import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EngineTranslationService } from './services/translation.service';
import { LabelService } from './services/label.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    environment.urls.ApiUrl + '/Localization/InfrastructureTranslations?language=',
    '',
  );
}

@NgModule({
  declarations: [],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
})
export class EngineTranslationsModule {
  static forRoot(): ModuleWithProviders<EngineTranslationsModule> {
    return {
      ngModule: EngineTranslationsModule,
      providers: [EngineTranslationService, LabelService],
    };
  }
}
