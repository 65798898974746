import { ChangeDetectionStrategy, Component, forwardRef, Inject, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Precision } from '../../../ui-components/components/duration/duration.service';
import { BaseFormControlDirective } from '../../directives/base-form-control.directive';
import { CULTURE_SERVICE, ICultureService } from '../../models/iculture-service.model';

@Component({
  selector: 'app-time-control',
  templateUrl: './time-control.component.html',
  styleUrls: ['./time-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: TimeControlComponent,
    },
  ],
})
export class TimeControlComponent extends BaseFormControlDirective {
  @Input() interval: number | undefined = 1;
  @Input() minPrecision: Precision = 'minutes';
  @Input() maxPrecision: Precision = 'hours';

  constructor(@Inject(CULTURE_SERVICE) cultureService: ICultureService, injector: Injector) {
    super(cultureService, injector);
  }
}
