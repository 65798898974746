import { Pipe, PipeTransform } from '@angular/core';
import { GridColumn } from '../models/grid-definition.model';

@Pipe({
  name: 'optionSetValue',
})
export class OptionSetValuePipe implements PipeTransform {
  transform(dataItem: any, column: GridColumn): number {
    if (!column.primaryField) {
      return -1;
    }

    const option = column.optionSet.values.find((e) => e.name === dataItem[column.primaryField]);
    return option.value;
  }
}
