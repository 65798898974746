import { ChangeDetectionStrategy, Component, forwardRef, Inject, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlDirective } from '@shared/reactive-controls/directives/base-form-control.directive';
import { SelectOption } from '@shared/reactive-controls/models/select-option.model';
import { CULTURE_SERVICE, ICultureService } from '../../models/iculture-service.model';

@Component({
  selector: 'app-multiselect-control',
  templateUrl: './multiselect-control.component.html',
  styleUrls: ['./multiselect-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: MultiselectControlComponent,
    },
  ],
})
export class MultiselectControlComponent extends BaseFormControlDirective {
  @Input() options: SelectOption[] = [];

  constructor(@Inject(CULTURE_SERVICE) cultureService: ICultureService, injector: Injector) {
    super(cultureService, injector);
  }

  public getLabel(option: SelectOption): string {
    return option ? (!option.label.startsWith('@') ? option.label : option.name) : '';
  }
}
