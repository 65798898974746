import { Pipe, PipeTransform } from '@angular/core';
import { GridColumn } from '../models/grid-definition.model';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dateTime' })
export class DateTimePipe implements PipeTransform {
  constructor(private _datePipe: DatePipe) {}

  transform(dataItem: any, column: GridColumn): string {
    if (!dataItem || !dataItem[column.primaryField]) return null;
    return this._datePipe.transform(dataItem[column.primaryField], this.getDateFormat(column));
  }

  private getDateFormat(column: GridColumn): string {
    const isDateOnlyColumn = column.type == 'date';
    switch (column.dateTimeFormatType) {
      case 'custom':
        return column.dateTimeFormat;
      case 'long':
        return isDateOnlyColumn ? 'longDate' : 'long';
      case 'short':
      default:
        return isDateOnlyColumn ? 'shortDate' : 'short';
    }
  }
}
