import { NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatNativeDateModule } from '@angular/material/core';
import { RippleModule } from '@progress/kendo-angular-ripple';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ChartComponent } from './components/chart/chart.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { OptionSetProgressBarComponent } from './components/optionset-progress-bar/optionset-progress-bar.component';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatChipsModule } from '@angular/material/chips';
import { ChartTypeSelectorComponent } from './components/chart/chart-type-selector/chart-type-selector.component';
import { DurationService } from './components/duration/duration.service';
import { ReactiveFormsModule } from '@angular/forms';
import { DurationDirective } from './components/duration/duration.directive';
import { DurationInput } from './components/duration/duration-input.component';
import { RatingComponent } from './components/rating/rating.component';
import { IconModule } from '../icon/icon.module';
import { ImgIconComponent } from './components/img-icon/img-icon.component';
import { A11yModule } from '@angular/cdk/a11y'
import { MatRadioModule } from '@angular/material/radio';

const importsAndExports = [
  MatExpansionModule,
  MatTabsModule,
  MatMenuModule,
  MatSnackBarModule,
  MatStepperModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatInputModule,
  MatFormFieldModule,
  MatGridListModule,
  MatDialogModule,
  MatTooltipModule,
  MatButtonModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatCardModule,
  MatDividerModule,
  MatSelectModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatSlideToggleModule,
  MatAutocompleteModule,
  MatButtonToggleModule,
  MatBottomSheetModule,
  MatBadgeModule,
  RippleModule,
  ButtonsModule,
  InputsModule,
  DropDownsModule,
  DateInputsModule,
  OverlayModule,
  MatAutocompleteModule,
  MatChipsModule,
  A11yModule,
  MatRadioModule
];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, NgxChartsModule, IconModule, importsAndExports],
  exports: [
    importsAndExports,
    ProgressBarComponent,
    OptionSetProgressBarComponent,
    ChartComponent,
    DurationDirective,
    DurationInput,
    RatingComponent,
    ImgIconComponent,
  ],
  declarations: [
    ProgressBarComponent,
    OptionSetProgressBarComponent,
    ChartComponent,
    ChartTypeSelectorComponent,
    DurationDirective,
    DurationInput,
    RatingComponent,
    ImgIconComponent,
  ],
  providers: [DurationService],
})
export class UiComponentsModule {}
