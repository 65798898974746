import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { BaseODataService } from '../../services/base-odata.service';
import { GenericODataServiceProvider } from '../../services/generic-odata-service-provider';

interface ILabelRequestsBatch {
  requests: ILabelRequest[];
  languageId: string;
}

interface ILabelRequest {
  name: string;
  languageId: string;
}

@Injectable()
export class LabelService {
  private readonly BATCH_SIZE = 25;
  private _labelODataService: BaseODataService<any>;
  private _queue$: BehaviorSubject<ILabelRequest[]> = new BehaviorSubject([]);
  private _cache$: BehaviorSubject<{ [languageId: string]: { [label: string]: any } }> = new BehaviorSubject({});

  constructor(_odataServiceProvider: GenericODataServiceProvider) {
    this._labelODataService = _odataServiceProvider.create('Label');
    const processQueue$ = this._queue$.pipe(
      filter((queue) => queue.length > 0),
      debounceTime(100),
      map((queue) => [...queue]),
      tap((queue) => {
        const languageBatches = this.popLabelBatches(queue);
        languageBatches.forEach((batch) => this.getLabelsBatchFromServer(batch));
      }),
    );
    processQueue$.subscribe();
  }

  getLabelByNameAndLanguage(name: string, languageId: string): Observable<any> {
    this.initializeLanguageDictionaryIfNew(languageId);
    this.queueLabelRequestIfNew({ name, languageId });
    return this._cache$.pipe(
      filter((cache) => !!cache[languageId][name]),
      map((cache) => cache[languageId][name]),
      take(1),
    );
  }

  private initializeLanguageDictionaryIfNew(languageId: string) {
    if (!this._cache$.value[languageId]) {
      const newCache = { ...this._cache$.value };
      newCache[languageId] = {};
      this._cache$.next(newCache);
    }
  }

  private queueLabelRequestIfNew(labelRequest: ILabelRequest) {
    const isAlreadyCached = this._cache$.value[labelRequest.languageId][labelRequest.name];
    const isAlreadyInQueue = this._queue$.value.some(
      (r) => r.name == labelRequest.name && r.languageId == labelRequest.languageId,
    );
    if (!isAlreadyCached && !isAlreadyInQueue) {
      this._queue$.next([...this._queue$.value, labelRequest]);
    }
  }

  private popLabelBatches(queue: ILabelRequest[]): ILabelRequestsBatch[] {
    const languageBatches: ILabelRequestsBatch[] = [];
    const newQueue = [];
    const distinctedLanguageIds = new Set(queue.map((r) => r.languageId));
    distinctedLanguageIds.forEach((languageId) => {
      const currentLanguageQueue = [...queue.filter((x) => x.languageId == languageId)];
      const batchRequests = currentLanguageQueue.splice(0, this.BATCH_SIZE);
      languageBatches.push({ requests: batchRequests, languageId });
      newQueue.push(...currentLanguageQueue);
    });
    this._queue$.next(newQueue);
    return languageBatches;
  }

  private getLabelsBatchFromServer(batch: ILabelRequestsBatch): void {
    const languageId = batch.languageId;
    this._labelODataService
      .query()
      .Filter(`Name in (${batch.requests.map((l) => `'${l.name}'`).join(',')}) and LanguageId eq ${languageId}`)
      .Exec()
      .pipe(
        take(1),
        tap((labels) => {
          const newCache = { ...this._cache$.value };
          labels.forEach((l) => (newCache[languageId][l.Name] = l));
          this._cache$.next(newCache);
        }),
      )
      .subscribe();
  }
}
