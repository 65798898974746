<div *ngIf="formControl">
  <mat-form-field class="control-input">
    <duration-input
      [formControl]="formControl"
      [minPrecision]="minPrecision"
      [maxPrecision]="maxPrecision"
      [interval]="interval"
      (blur)="onBlur()"
      matInput
      [placeholder]="label"
      [name]="name"></duration-input>
  </mat-form-field>
  <app-control-error [control]="formControl" [offset]="-16"></app-control-error>
</div>
