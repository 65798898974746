import { Directive, Inject, Injector, Input, Optional, SkipSelf } from '@angular/core';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';
import { EngineFormControlDirective } from './engine-form-control.directive';
import { DescriptionControlComponent } from '@shared/reactive-controls/components/description/description-control.component';
import { DescriptionControlDto } from '@core/services/api-clients';

@Directive({
  selector: 'app-description-control[engineDescriptionFormControl]',
  providers: [{ provide: EngineFormControlDirective, useExisting: EngineDescriptionFormControlDirective }],
})
export class EngineDescriptionFormControlDirective extends EngineFormControlDirective {
  protected get _descriptionBaseControl(): DescriptionControlComponent {
    return this._baseControl as DescriptionControlComponent;
  }

  @Input() set engineDescriptionFormControlDefinition(definition: DescriptionControlDto) {
    this._descriptionBaseControl.text = definition.value;
    this.engineControlDefinition = definition;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
  ) {
    super(parentWidget, scriptRunnerService, injector);
  }
}
