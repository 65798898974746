import { Directive, Inject, Injector, Input, Optional, SkipSelf } from '@angular/core';
import { IButtonContext } from '@core/execution-context/execution-context';
import { ButtonControlDto, IconDto } from '@core/services/api-clients';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';
import { WidgetType } from '@core/widgets/models/widget-type';
import { ButtonControlComponent } from '@shared/reactive-controls/components/button/button-control.component';
import { Observable } from 'rxjs';
import { IExecutionContext } from '../../../engine-sdk';
import { EngineFormControlDirective } from './engine-form-control.directive';

@Directive({
  selector: 'app-button-control[engineButtonFormControl]',
  providers: [{ provide: EngineFormControlDirective, useExisting: EngineButtonFormControlDirective }],
})
export class EngineButtonFormControlDirective extends EngineFormControlDirective implements IButtonContext {
  protected get _buttonBaseControl(): ButtonControlComponent {
    return this._baseControl as ButtonControlComponent;
  }

  @Input() set engineButtonControlDefinition(definition: ButtonControlDto) {
    this._buttonBaseControl.icon = definition.icon;
    this._buttonBaseControl.tooltipText = definition.tooltipText;
    this._buttonBaseControl.dataContext = this.getDataContext();
    this.engineControlDefinition = definition;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
  ) {
    super(parentWidget, scriptRunnerService, injector);
    this._isOnClickEventSupported = true;
  }

  setFontIcon(fontIconName: string): void {
    this._buttonBaseControl.icon = { fontIconName } as IconDto;
    this._changeDetector.markForCheck();
  }

  setResourceIcon(iconRelativePath: string): void {
    this._buttonBaseControl.icon = { iconRelativePath } as IconDto;
    this._changeDetector.markForCheck();
  }

  setLabel(value: string) {
    this._buttonBaseControl.label = value ?? '';
    this._changeDetector.markForCheck();
  }

  setDisabled(value: boolean) {
    this._baseControl.setDisabledState(value);
    this._changeDetector.markForCheck();
  }

  getVisibility(): boolean {
    return this._baseControl.isVisible;
  }

  setVisibility(value: boolean) {
    this._baseControl.isVisible = value;
    this._changeDetector.markForCheck();
  }

  getWidgetType(): WidgetType {
    return WidgetType.FormButton;
  }

  getExecutionContext(): Observable<IExecutionContext> {
    return this._contextService.createFormButtonExecutionContext(this, this._events$, this.getDataContext());
  }
}
