import { Action } from '@ngrx/store';
import { MainMenuDto, MenuItemDto } from '@core/services/api-clients';

export enum MenuActionTypes {
  LoadMainMenu = '[Menu] Load Main Menu',
  LoadMainMenuSuccess = '[Menu] Load Main Menu Success',

  SelectMenuArea = '[Menu] Select Menu Area',

  SelectMenuGroup = '[Menu] Select Menu Group',
  SelectMenuGroupSuccess = '[Menu] Select Menu Group Success',

  SelectMenuItem = '[Menu] Select Menu Item',
  SelectMenuItemSuccess = '[Menu] Select Menu Item Success',

  AddRecentMenuItem = '[Menu] Add Recent Menu Item',
  AddRecentMenuItemSuccess = '[Menu] Add Recent Menu Item Success',

  AddFavoriteMenuItem = '[Menu] Add Favorite Menu Item',
  AddFavoriteMenuItemSuccess = '[Menu] Add Favorite Menu Item Success',

  RemoveFavoriteMenuItem = '[Menu] Remove Favorite Menu Item',
  RemoveFavoriteMenuItemSuccess = '[Menu] Remove Favorite Menu Item Success',

  DisableMainMenuPopup = '[Menu] Disable Popup',
  EnableMainMenuPopup = '[Menu] Enable Popup',
  ToggleIsFavoriteMode = '[Menu] Toggle Is Favorite Mode',
}

export class LoadMainMenu implements Action {
  public readonly type = MenuActionTypes.LoadMainMenu;
}

export class LoadMainMenuSuccess implements Action {
  public readonly type = MenuActionTypes.LoadMainMenuSuccess;
  public constructor(
    public payload: {
      mainMenu: MainMenuDto;
      selectedMenuAreaId?: string;
      selectedMenuGroupId?: string;
      selectedMenuItemId?: string;
    },
  ) {}
}

export class SelectMenuArea implements Action {
  public readonly type = MenuActionTypes.SelectMenuArea;
  public constructor(public payload: { menuAreaId: string }) {}
}

export class SelectMenuGroup implements Action {
  public readonly type = MenuActionTypes.SelectMenuGroup;
  public constructor(
    public payload: {
      menuGroupId: string;
      preventDefaultMenuItemSelection?: boolean;
    },
  ) {}
}

export class SelectMenuGroupSuccess implements Action {
  public readonly type = MenuActionTypes.SelectMenuGroupSuccess;
  public constructor(public payload: { menuGroupId: string }) {}
}

export class SelectMenuItem implements Action {
  public readonly type = MenuActionTypes.SelectMenuItem;
  public constructor(public payload: { menuItem: MenuItemDto }) {}
}

export class SelectMenuItemSuccess implements Action {
  public readonly type = MenuActionTypes.SelectMenuItemSuccess;
  public constructor(public payload: { menuItem: MenuItemDto }) {}
}

export class AddRecentMenuItem implements Action {
  public readonly type = MenuActionTypes.AddRecentMenuItem;
  public constructor(public payload: { menuItem: MenuItemDto }) {}
}

export class AddRecentMenuItemSuccess implements Action {
  public readonly type = MenuActionTypes.AddRecentMenuItemSuccess;
  public constructor(public payload: { menuItem: MenuItemDto }) {}
}

export class AddFavoriteMenuItem implements Action {
  public readonly type = MenuActionTypes.AddFavoriteMenuItem;
  public constructor(public payload: { menuItem: MenuItemDto }) {}
}

export class AddFavoriteMenuItemSuccess implements Action {
  public readonly type = MenuActionTypes.AddFavoriteMenuItemSuccess;
  public constructor(public payload: { menuItem: MenuItemDto }) {}
}

export class RemoveFavoriteMenuItem implements Action {
  public readonly type = MenuActionTypes.RemoveFavoriteMenuItem;
  public constructor(public payload: { menuItem: MenuItemDto }) {}
}

export class RemoveFavoriteMenuItemSuccess implements Action {
  public readonly type = MenuActionTypes.RemoveFavoriteMenuItemSuccess;
  public constructor(public payload: { menuItem: MenuItemDto }) {}
}

export class EnableMainMenuPopup implements Action {
  public readonly type = MenuActionTypes.EnableMainMenuPopup;
}

export class DisableMainMenuPopup implements Action {
  public readonly type = MenuActionTypes.DisableMainMenuPopup;
}

export class ToggleIsFavoriteMode implements Action {
  public readonly type = MenuActionTypes.ToggleIsFavoriteMode;
}

export type MenuActions =
  | LoadMainMenu
  | LoadMainMenuSuccess
  | AddRecentMenuItem
  | AddRecentMenuItemSuccess
  | AddFavoriteMenuItem
  | AddFavoriteMenuItemSuccess
  | RemoveFavoriteMenuItem
  | RemoveFavoriteMenuItemSuccess
  | SelectMenuArea
  | SelectMenuGroup
  | SelectMenuGroupSuccess
  | SelectMenuItem
  | SelectMenuItemSuccess
  | EnableMainMenuPopup
  | DisableMainMenuPopup
  | ToggleIsFavoriteMode;
