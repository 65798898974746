<mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="onSelectedTabChange($event)">
  <mat-tab
    class="container"
    *ngFor="let tab of tabs; index as i"
    [disabled]="!tab.isVisible"
    engineFormTab
    [id]="tab.id"
    [name]="tab.name">
    <ng-template mat-tab-label>
      <app-form-tab-notification-badge [formTab]="tab" class="title-badge"></app-form-tab-notification-badge>
      {{ tab.title }}
    </ng-template>
    <app-form-tab-notification-bar [formTab]="tab"></app-form-tab-notification-bar>
    <app-form-grid *ngIf="tab.layoutGrid" [data]="gridsData[tab.id]"> </app-form-grid>
  </mat-tab>
</mat-tab-group>
