import { Injectable, InjectionToken } from '@angular/core';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  isCompositeFilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';
import { GridColumnType, GridDefinition } from '@shared/grid/models/grid-definition.model';
import { ODataState } from '@shared/odata/models/odata-state.model';
import { BaseStateService } from '@shared/utils/services/base-state.service';
import { Observable } from 'rxjs';

export const ENGINE_VIEW_STATE_SERVICE = new InjectionToken<EngineViewStateService>('EngineViewStateService');

export enum EngineFilterType {
  Default = '$isDefault',
  System = '$isSystem',
  Custom = '$isCustom',
  GlobalSearch = '$isGlobalSearch',
}

export interface IEngineViewState {
  // Query provided by View definition stored in a database
  defaultQuery?: ODataState;

  // Query provided by Engine mechanisms like entity relations (e.g. for subgrids)
  systemQuery?: ODataState;

  // Query applied by custom scripts
  customQuery?: ODataState;

  // Query applied by UI interaction
  userQuery?: ODataState;

  // Query applied by UI interaction in UrlState format
  urlState?: UrlState;

  // Final query - combination of defaultQuery, systemQuery, customQuery, userQuery
  finalQuery?: ODataState;

  globalSearch?: string;
}

export interface UrlState {
  skip?: number;
  take?: number;
  sort?: SortDescriptor[];
  filter?: CompositeFilterDescriptor;
  globalSearch?: string;
}

interface SearchableField {
  field: string;
  type: GridColumnType;
}

export interface IEngineViewStateService {
  getViewStateAsync(): Observable<IEngineViewState>;
  getViewState(): IEngineViewState;
  initialize(gridDefinition: GridDefinition, urlState: UrlState);
  setSystemQuery(query: ODataState);
  setAdditionalFilters(value: CompositeFilterDescriptor);
  removeAdditionalFilters();
  setGlobalSearch(globalSearch: string);
  setState(state: ODataState);
}

@Injectable()
export class EngineViewStateService extends BaseStateService<IEngineViewState> implements IEngineViewStateService {
  private _gridDefinition: GridDefinition;

  constructor() {
    super({});
  }

  getViewStateAsync(): Observable<IEngineViewState> {
    return this.getStateAsync();
  }

  getViewState(): IEngineViewState {
    return this.getState();
  }

  initialize(gridDefinition: GridDefinition, urlState: UrlState) {
    this._gridDefinition = gridDefinition;
    const currentState = this.getState();
    const newState = { ...currentState };
    if (gridDefinition) {
      newState.defaultQuery = gridDefinition.defaultQuery;
      if (currentState.defaultQuery?.filter) {
        newState.defaultQuery.filter[EngineFilterType.Default] = true;
      }

      newState.customQuery = null;
      newState.userQuery = {
        skip: urlState?.skip ?? currentState.defaultQuery?.skip ?? 0,
        take: urlState?.take ?? gridDefinition?.defaultPageSize ?? currentState.defaultQuery?.take ?? 20,
        sort: urlState?.sort ?? currentState.defaultQuery?.sort ?? [],
      };

      if (urlState) {
        newState.urlState = urlState;

        if (urlState.filter) {
          newState.userQuery.filter = urlState.filter;
          newState.globalSearch = null;
        } else if (urlState.globalSearch) {
          newState.userQuery.filter = this.createFilterForGlobalSearch(urlState.globalSearch);
          newState.globalSearch = urlState.globalSearch;
        }
      }

      newState.finalQuery = this.createFinalQuery(newState);
    }
    this.updateState(newState);
  }

  setSystemQuery(query: ODataState) {
    const currentState = this.getState();
    const newState = { ...currentState, systemQuery: query };
    if (newState.systemQuery?.filter) {
      newState.systemQuery.filter[EngineFilterType.System] = true;
    }
    newState.finalQuery = this.createFinalQuery(newState);
    this.updateState(newState);
  }

  setAdditionalFilters(value: CompositeFilterDescriptor): void {
    const currentState = this.getState();
    const newState = { ...currentState };
    newState.customQuery = value ? { filter: { ...value } } : null;
    if (newState.customQuery?.filter) {
      newState.customQuery.filter[EngineFilterType.Custom] = true;
    }
    newState.finalQuery = this.createFinalQuery(newState);
    this.updateState(newState);
  }

  removeAdditionalFilters(): void {
    const currentState = this.getState();
    const newState = { ...currentState, customQuery: null };
    newState.finalQuery = this.createFinalQuery(newState);
    this.updateState(newState);
  }

  setState(state: ODataState) {
    const currentState = this.getState();
    const newState = { ...currentState, userQuery: state };
    if (currentState.globalSearch) {
      newState.userQuery.filter = this.createFilterForGlobalSearch(currentState.globalSearch);
    } else if (state.filter?.filters?.length > 0) {
      newState.userQuery.filter = {
        logic: 'and',
        filters: state.filter.filters.filter(
          (x) => !x[EngineFilterType.Default] && !x[EngineFilterType.Custom] && !x[EngineFilterType.System],
        ),
      };
    }
    newState.finalQuery = this.createFinalQuery(newState);
    newState.urlState = this.createUrlState(newState);
    this.updateState(newState);
  }

  setGlobalSearch(globalSearch: string) {
    const currentState = this.getState();
    const newState = { ...currentState, globalSearch };
    if (newState.globalSearch) {
      newState.userQuery.filter = this.createFilterForGlobalSearch(globalSearch);
      newState.userQuery.skip = 0;
    } else {
      newState.userQuery.filter = null;
    }
    newState.finalQuery = this.createFinalQuery(newState);
    newState.urlState = this.createUrlState(newState);
    this.updateState(newState);
  }

  private createFilterForGlobalSearch(searchValue: string): CompositeFilterDescriptor {
    let filter: CompositeFilterDescriptor;
    if (searchValue !== null && searchValue !== undefined && searchValue.length !== 0) {
      filter = <CompositeFilterDescriptor>{
        logic: 'or',
        filters: [],
      };
      filter[EngineFilterType.GlobalSearch] = true;

      this.getSearchableFields().forEach((searchableField) =>
        filter.filters.push({
          field: searchableField.field,
          operator: 'contains',
          value: searchValue,
        }),
      );
    }

    return filter;
  }

  private getSearchableFields(): SearchableField[] {
    return (this._gridDefinition.columns ?? [])
      .filter((x) => x.type === 'text' || x.type === 'guid')
      .map((column) => {
        return {
          field: column.primaryField.replace('.', '/'),
          type: column.type,
        };
      });
  }

  private createFinalQuery(state: IEngineViewState): ODataState {
    let finalQuery: ODataState = state.defaultQuery
      ? {
          ...state.defaultQuery,
          take: state.defaultQuery.take || this._gridDefinition.defaultPageSize,
        }
      : {
          skip: 0,
          take: this._gridDefinition?.defaultPageSize ?? 20,
        };

    if (state.systemQuery) {
      finalQuery = this.mergeState(finalQuery, state.systemQuery);
    }

    if (state.customQuery) {
      finalQuery = this.mergeState(finalQuery, state.customQuery);
    }

    if (state.userQuery) {
      finalQuery = this.mergeState(finalQuery, state.userQuery);
      finalQuery.skip = state.userQuery.skip ?? finalQuery.skip;
    }

    if (finalQuery.filter?.filters?.length > 0) {
      this.setBaseTypes(finalQuery.filter.filters);
    }

    return finalQuery;
  }

  private mergeState(finalQuery: ODataState, state: ODataState) {
    const result: ODataState = { ...finalQuery };

    result.take = state.take ?? result.take;
    result.sort = state.sort && state.sort.length > 0 ? state.sort : (result.sort ?? []);

    if (state?.filter?.filters?.length > 0) {
      if (result.filter) {
        result.filter = {
          logic: 'and',
          filters: [result.filter, state.filter],
        };
      } else {
        result.filter = state.filter;
      }
    }

    result.customFilter = state.customFilter
      ? result.customFilter
        ? `(${result.customFilter}) and (${state.customFilter})`
        : state.customFilter
      : result.customFilter;

    return result;
  }

  private createUrlState(state: IEngineViewState): UrlState {
    let result: UrlState = null;

    if (state.userQuery) {
      result = {};

      if (state.userQuery.skip) result.skip = state.userQuery.skip;

      if (state.userQuery.take) result.take = state.userQuery.take;

      if (state.userQuery.sort && state.userQuery.sort.length > 0) result.sort = state.userQuery.sort;

      if (state.globalSearch) result.globalSearch = state.globalSearch;
      else if (state.userQuery.filter?.filters.length > 0) {
        result.filter = state.userQuery.filter;
      }
    }

    return result;
  }

  //TODO: Should be done in ODataState - MR
  private setBaseTypes(filters: Array<FilterDescriptor | CompositeFilterDescriptor>) {
    if (filters) {
      for (const filter of filters) {
        if (filter) {
          if (isCompositeFilterDescriptor(filter)) {
            this.setBaseTypes(filter.filters);
          } else if (typeof filter.field === 'string' && filter.field.endsWith('Id')) {
            filter['baseType'] = 'guid';
          }
        }
      }
    }
  }
}
