<div>
  <mat-form-field>
    <mat-select [value]="entity" (selectionChange)="onEntityChange($event.value)">
      <mat-option *ngFor="let entity of entities" [value]="entity">
        {{entity.name}}
      </mat-option>
    </mat-select>
    <button type="button" matSuffix mat-icon-button class="autocomplete-action-button">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </mat-form-field>
  <app-query-builder *ngIf="entity"
                     [entity]="entity"
                     (conditionRuleChange)="onConditionRuleChange($event)">
  </app-query-builder>
  <div class="q-json-container">
    <pre>{{conditionRule | json}}</pre>
  </div>
</div>

