import { Directive, Inject, Injector, Input, Optional, SkipSelf } from '@angular/core';
import { TimeControlDto, TimePrecision } from '@core/services/api-clients';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';
import { EngineFormControlDirective } from './engine-form-control.directive';
import { TimeControlComponent } from '../../../shared/reactive-controls/components/time/time-control.component';
import { Precision } from '../../../shared/ui-components/components/duration/duration.service';

@Directive({
  selector: 'app-time-control[engineTimeFormControl]',
  providers: [{ provide: EngineFormControlDirective, useExisting: EngineTimeFormControlDirective }],
})
export class EngineTimeFormControlDirective extends EngineFormControlDirective {
  protected get _timeBaseControl(): TimeControlComponent {
    return this._baseControl as TimeControlComponent;
  }

  @Input() set engineTimeControlDefinition(definition: TimeControlDto) {
    this.engineControlDefinition = definition;
    this._timeBaseControl.interval = definition.interval;
    this._timeBaseControl.minPrecision = this.mapTimePrecision(definition.minPrecision);
    this._timeBaseControl.maxPrecision = this.mapTimePrecision(definition.maxPrecision);
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
  ) {
    super(parentWidget, scriptRunnerService, injector);
  }

  private mapTimePrecision(timePrecision?: TimePrecision): Precision {
    switch (timePrecision) {
      case TimePrecision.Days:
        return 'days';
      case TimePrecision.Hours:
        return 'hours';
      case TimePrecision.Minutes:
        return 'minutes';
      case TimePrecision.Seconds:
        return 'seconds';
      default:
        return undefined;
    }
  }
}
