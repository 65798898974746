import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { DurationService } from '../../ui-components/components/duration/duration.service';
import { GridColumn } from '../models/grid-definition.model';

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {
  constructor(private _durationService: DurationService) {}

  transform(dataItem: any, column: GridColumn): string {
    if (!dataItem || !dataItem[column.primaryField]) return null;

    let duration = moment.duration(dataItem[column.primaryField]);
    let maxPrecision = column.timeMaxPrecision ? column.timeMaxPrecision : 'hours';
    let minPrecision = column.timeMinPrecision ? column.timeMinPrecision : 'minutes';

    const days = this._durationService.getDays(duration, maxPrecision);
    const hours = this._durationService.getHours(duration, maxPrecision);
    const minutes = this._durationService.getMinutes(duration, maxPrecision);
    const seconds = this._durationService.getSeconds(duration, maxPrecision);

    let isDaysVisible = this._durationService.isVisible('days', maxPrecision, minPrecision);
    let isHoursVisible = this._durationService.isVisible('hours', maxPrecision, minPrecision);
    let isMinutesVisible = this._durationService.isVisible('minutes', maxPrecision, minPrecision);
    let isSecondsVisible = this._durationService.isVisible('seconds', maxPrecision, minPrecision);

    const textDuration = [
      { text: `${days}`, condition: isDaysVisible },
      { text: `. `, condition: isDaysVisible && isHoursVisible },
      { text: `${hours}`, condition: isHoursVisible },
      { text: `:`, condition: isHoursVisible && isMinutesVisible },
      { text: `${minutes}`, condition: isMinutesVisible },
      { text: `:`, condition: isMinutesVisible && isSecondsVisible },
      { text: `${seconds}`, condition: isSecondsVisible },
    ]
      .flatMap((precision) => (precision.condition ? precision.text : []))
      .join('');

    return textDuration;
  }
}
