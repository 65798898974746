<app-chart-type-selector
  *ngIf="configuration.showTypeSelector"
  [selectedType]="configuration.chartType"
  (onTypeSelected)="onChartTypeSelected($event)"></app-chart-type-selector>
<div
  class="chart-container"
  #containerRef
  *ngIf="configuration"
  [ngSwitch]="configuration.chartType"
  [class.legend-below]="configuration.legendPosition == 'below'">
  <!-- Vertical Bar Charts -->
  <ngx-charts-bar-vertical
    *ngSwitchCase="ChartTypes.VerticalBar"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [legend]="configuration.showLegend"
    [legendPosition]="getLegendPosition()"
    [xAxis]="configuration.showXAxis"
    [showXAxisLabel]="configuration.showXAxisLabel && !!configuration.xAxisLabel"
    [xAxisLabel]="configuration.xAxisLabel"
    [yAxis]="configuration.showYAxis"
    [showYAxisLabel]="configuration.showYAxisLabel && !!configuration.yAxisLabel"
    [yAxisLabel]="configuration.yAxisLabel"
    [animations]="configuration.animations"
    [gradient]="configuration.gradient"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-bar-vertical>
  <ngx-charts-bar-vertical-stacked
    *ngSwitchCase="ChartTypes.StackedVerticalBar"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [legend]="configuration.showLegend"
    [legendPosition]="getLegendPosition()"
    [xAxis]="configuration.showXAxis"
    [showXAxisLabel]="configuration.showXAxisLabel && !!configuration.xAxisLabel"
    [xAxisLabel]="configuration.xAxisLabel"
    [yAxis]="configuration.showYAxis"
    [showYAxisLabel]="configuration.showYAxisLabel && !!configuration.yAxisLabel"
    [yAxisLabel]="configuration.yAxisLabel"
    [gradient]="configuration.gradient"
    [animations]="configuration.animations"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-bar-vertical-stacked>
  <ngx-charts-bar-vertical-2d
    *ngSwitchCase="ChartTypes.GroupedVerticalBar"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [legend]="configuration.showLegend"
    [legendPosition]="getLegendPosition()"
    [xAxis]="configuration.showXAxis"
    [showXAxisLabel]="configuration.showXAxisLabel && !!configuration.xAxisLabel"
    [xAxisLabel]="configuration.xAxisLabel"
    [yAxis]="configuration.showYAxis"
    [showYAxisLabel]="configuration.showYAxisLabel && !!configuration.yAxisLabel"
    [yAxisLabel]="configuration.yAxisLabel"
    [gradient]="configuration.gradient"
    [animations]="configuration.animations"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-bar-vertical-2d>
  <ngx-charts-bar-vertical-normalized
    *ngSwitchCase="ChartTypes.NormalizedVerticalBar"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [legend]="configuration.showLegend"
    [legendPosition]="getLegendPosition()"
    [xAxis]="configuration.showXAxis"
    [showXAxisLabel]="configuration.showXAxisLabel && !!configuration.xAxisLabel"
    [xAxisLabel]="configuration.xAxisLabel"
    [yAxis]="configuration.showYAxis"
    [showYAxisLabel]="configuration.showYAxisLabel && !!configuration.yAxisLabel"
    [yAxisLabel]="configuration.yAxisLabel"
    [gradient]="configuration.gradient"
    [animations]="configuration.animations"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-bar-vertical-normalized>

  <!-- Horizontal Bar Charts -->
  <ngx-charts-bar-horizontal
    *ngSwitchCase="ChartTypes.HorizontalBar"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [legend]="configuration.showLegend"
    [legendPosition]="getLegendPosition()"
    [xAxis]="configuration.showXAxis"
    [showXAxisLabel]="configuration.showXAxisLabel && !!configuration.xAxisLabel"
    [xAxisLabel]="configuration.xAxisLabel"
    [yAxis]="configuration.showYAxis"
    [showYAxisLabel]="configuration.showYAxisLabel && !!configuration.yAxisLabel"
    [yAxisLabel]="configuration.yAxisLabel"
    [gradient]="configuration.gradient"
    [animations]="configuration.animations"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-bar-horizontal>
  <ngx-charts-bar-horizontal-stacked
    *ngSwitchCase="ChartTypes.StackedHorizontalBar"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [legend]="configuration.showLegend"
    [legendPosition]="getLegendPosition()"
    [xAxis]="configuration.showXAxis"
    [showXAxisLabel]="configuration.showXAxisLabel && !!configuration.xAxisLabel"
    [xAxisLabel]="configuration.xAxisLabel"
    [yAxis]="configuration.showYAxis"
    [showYAxisLabel]="configuration.showYAxisLabel && !!configuration.yAxisLabel"
    [yAxisLabel]="configuration.yAxisLabel"
    [gradient]="configuration.gradient"
    [animations]="configuration.animations"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-bar-horizontal-stacked>
  <ngx-charts-bar-horizontal-2d
    *ngSwitchCase="ChartTypes.GroupedHorizontalBar"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [legend]="configuration.showLegend"
    [legendPosition]="getLegendPosition()"
    [xAxis]="configuration.showXAxis"
    [showXAxisLabel]="configuration.showXAxisLabel && !!configuration.xAxisLabel"
    [xAxisLabel]="configuration.xAxisLabel"
    [yAxis]="configuration.showYAxis"
    [showYAxisLabel]="configuration.showYAxisLabel && !!configuration.yAxisLabel"
    [yAxisLabel]="configuration.yAxisLabel"
    [gradient]="configuration.gradient"
    [animations]="configuration.animations"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-bar-horizontal-2d>
  <ngx-charts-bar-horizontal-normalized
    *ngSwitchCase="ChartTypes.NormalizedHorizontalBar"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [legend]="configuration.showLegend"
    [legendPosition]="getLegendPosition()"
    [xAxis]="configuration.showXAxis"
    [showXAxisLabel]="configuration.showXAxisLabel && !!configuration.xAxisLabel"
    [xAxisLabel]="configuration.xAxisLabel"
    [yAxis]="configuration.showYAxis"
    [showYAxisLabel]="configuration.showYAxisLabel && !!configuration.yAxisLabel"
    [yAxisLabel]="configuration.yAxisLabel"
    [gradient]="configuration.gradient"
    [animations]="configuration.animations"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-bar-horizontal-normalized>

  <!-- Pie Charts -->
  <ngx-charts-pie-chart
    *ngSwitchCase="ChartTypes.Pie"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [legend]="configuration.showLegend"
    [legendPosition]="getLegendPosition()"
    [labels]="configuration.showXAxis"
    [doughnut]="configuration.doughnut"
    [animations]="configuration.animations"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-pie-chart>
  <ngx-charts-advanced-pie-chart
    *ngSwitchCase="ChartTypes.AdvancedPie"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [animations]="configuration.animations"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-advanced-pie-chart>
  <ngx-charts-pie-grid
    *ngSwitchCase="ChartTypes.PieGrid"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [animations]="configuration.animations"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-pie-grid>

  <!-- Line and Area Charts -->
  <ngx-charts-line-chart
    *ngSwitchCase="ChartTypes.Line"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [legend]="configuration.showLegend"
    [legendPosition]="getLegendPosition()"
    [xAxis]="configuration.showXAxis"
    [showXAxisLabel]="configuration.showXAxisLabel && !!configuration.xAxisLabel"
    [xAxisLabel]="configuration.xAxisLabel"
    [yAxis]="configuration.showYAxis"
    [showYAxisLabel]="configuration.showYAxisLabel && !!configuration.yAxisLabel"
    [yAxisLabel]="configuration.yAxisLabel"
    [animations]="configuration.animations"
    [gradient]="configuration.gradient"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-line-chart>
  <ngx-charts-area-chart
    *ngSwitchCase="ChartTypes.Area"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [legend]="configuration.showLegend"
    [legendPosition]="getLegendPosition()"
    [xAxis]="configuration.showXAxis"
    [showXAxisLabel]="configuration.showXAxisLabel && !!configuration.xAxisLabel"
    [xAxisLabel]="configuration.xAxisLabel"
    [yAxis]="configuration.showYAxis"
    [showYAxisLabel]="configuration.showYAxisLabel && !!configuration.yAxisLabel"
    [yAxisLabel]="configuration.yAxisLabel"
    [animations]="configuration.animations"
    [gradient]="configuration.gradient"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-area-chart>
  <ngx-charts-area-chart-stacked
    *ngSwitchCase="ChartTypes.StackedArea"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [legend]="configuration.showLegend"
    [legendPosition]="getLegendPosition()"
    [xAxis]="configuration.showXAxis"
    [showXAxisLabel]="configuration.showXAxisLabel && !!configuration.xAxisLabel"
    [xAxisLabel]="configuration.xAxisLabel"
    [yAxis]="configuration.showYAxis"
    [showYAxisLabel]="configuration.showYAxisLabel && !!configuration.yAxisLabel"
    [yAxisLabel]="configuration.yAxisLabel"
    [animations]="configuration.animations"
    [gradient]="configuration.gradient"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-area-chart-stacked>
  <ngx-charts-area-chart-normalized
    *ngSwitchCase="ChartTypes.NormalizedArea"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [legend]="configuration.showLegend"
    [legendPosition]="getLegendPosition()"
    [xAxis]="configuration.showXAxis"
    [showXAxisLabel]="configuration.showXAxisLabel && !!configuration.xAxisLabel"
    [xAxisLabel]="configuration.xAxisLabel"
    [yAxis]="configuration.showYAxis"
    [showYAxisLabel]="configuration.showYAxisLabel && !!configuration.yAxisLabel"
    [yAxisLabel]="configuration.yAxisLabel"
    [animations]="configuration.animations"
    [gradient]="configuration.gradient"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-area-chart-normalized>

  <!-- Other Charts -->
  <ngx-charts-number-card
    *ngSwitchCase="ChartTypes.Card"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [cardColor]="configuration.cardColor"
    [animations]="configuration.animations"
    [style]="getChartStyles()"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-number-card>
  <ngx-charts-gauge
    *ngSwitchCase="ChartTypes.Gauge"
    [results]="configuration.data"
    [view]="configuration.view ?? [containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="getScheme()"
    [legend]="configuration.showLegend"
    [legendPosition]="getLegendPosition()"
    [animations]="configuration.animations"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-gauge>
</div>
