import { ChangeDetectionStrategy, Component, forwardRef, Inject, InjectionToken, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateValidators } from '../../validators/date.validators';
import { BaseFormControlDirective } from '../../directives/base-form-control.directive';
import moment from 'moment';
import { CULTURE_SERVICE, ICultureService } from '../../models/iculture-service.model';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';

export const OWL_DATE_TIME_ADAPTER = new InjectionToken<DateTimeAdapter<any>>('OWL_DATE_TIME_ADAPTER');

@Component({
  selector: 'app-datetime-control',
  templateUrl: './datetime-control.component.html',
  styleUrls: ['./datetime-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimeControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: DatetimeControlComponent,
    },
    {
      provide: DateTimeAdapter,
      useExisting: OWL_DATE_TIME_ADAPTER,
    },
  ],
})
export class DatetimeControlComponent extends BaseFormControlDirective {
  private _minValue: moment.Moment | Date | undefined | null;
  private _maxValue: moment.Moment | Date | undefined | null;

  @Input() stepMinute: number = 1;
  @Input() firstDayOfWeek: number = 1;
  @Input() minDate: moment.Moment | Date;
  @Input() maxDate: moment.Moment | Date;

  override writeValue(value: any): void {
    const valueAsDate = value ? new Date(value) : undefined;
    if (this.formControl.value?.getTime() !== valueAsDate?.getTime()) {
      this.formControl.setValue(valueAsDate);
    }
  }

  @Input() set minValue(minDate: moment.Moment | Date | undefined | null) {
    if (this.isDate(minDate)) {
      this._minValue = minDate;
      this.addValidator('dateMinimum', DateValidators.dateMinimum(minDate));
    } else {
      this._minValue = null;
      this.removeValidator('dateMinimum');
    }
  }

  @Input() set maxValue(maxDate: moment.Moment | Date | undefined | null) {
    if (this.isDate(maxDate)) {
      this._maxValue = maxDate;
      this.addValidator('dateMaximum', DateValidators.dateMaximum(maxDate));
    } else {
      this._maxValue = null;
      this.removeValidator('dateMaximum');
    }
  }

  get maxValue() {
    return this._maxValue;
  }

  get minValue() {
    return this._minValue;
  }

  constructor(@Inject(CULTURE_SERVICE) cultureService: ICultureService, injector: Injector) {
    super(cultureService, injector);
  }

  clear() {
    if (this.formControl.disabled) return;
    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
    this.formControl.patchValue(null);
  }

  private isDate(date: any) {
    return moment.isDate(date) || moment.isMoment(date);
  }
}
