<div *ngIf="conditionRule">
  <div>
    <div class="q-button-group q-right-align">
      <button type="button" mat-icon-button (click)="addRule()">
        <mat-icon>add</mat-icon>
      </button>
      <button type="button" mat-icon-button (click)="addConditionRule()">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </div>
    <mat-radio-group [value]="conditionRule.condition" (change)="onConditionChange($event.value)">
      <mat-radio-button *ngFor="let logicOperator of logicOperators" [value]="logicOperator.value">
        {{logicOperator.text}}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="q-tree-container">
    <ul class="q-tree">
      <li class="q-row q-connector q-transition q-rule" *ngFor="let rule of conditionRule.rules; index as index">
        <div class="q-button-group q-right-align">
          <button type="button" mat-icon-button (click)="remove(index)">
            <mat-icon>remove</mat-icon>
          </button>
        </div>
        <app-query-builder-rule [fields]="fields"
                                [rule]="rule"
                                (ruleChange)="onRuleChange(index, $event)">
        </app-query-builder-rule>
      </li>
      <li class="q-row q-connector q-transition q-rule" *ngFor="let conditionRule of conditionRule.conditionRules; index as index">
        <div class="q-button-group q-right-align">
          <button type="button" mat-icon-button (click)="removeConditionRule(index)">
            <mat-icon>remove</mat-icon>
          </button>
        </div>
        <app-query-builder [entity]="entity"
                           [conditionRule]="conditionRule"
                           (conditionRuleChange)="onConditionRuleChange(index, $event)">
        </app-query-builder>
      </li>
    </ul>
  </div>
</div>
