<div [formGroup]="ruleForm">
  <mat-form-field>
    <mat-select formControlName="field">
      <mat-option *ngFor="let field of fields" [value]="field.name">
        {{field.name}}
      </mat-option>
    </mat-select>
    <button type="button" matSuffix mat-icon-button class="autocomplete-action-button">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field>
    <mat-select formControlName="operator">
      <mat-option *ngFor="let operator of operators" [value]="operator.value">
        {{operator.text}}
      </mat-option>
    </mat-select>
    <button type="button" matSuffix mat-icon-button class="autocomplete-action-button">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field>
    <input matInput formControlName="value">
  </mat-form-field>
</div>
