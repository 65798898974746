<span *ngIf="column.type == 'datetime' || column.type == 'date'" title="{{ dataItem | dateTime: column }}">
  {{ dataItem | dateTime: column }}
</span>
<span *ngIf="column.type == 'boolean'">
  <input type="checkbox" [checked]="dataItem[column.primaryField]" disabled />
</span>
<span *ngIf="column.type == 'decimal'" title="{{ dataItem[column.primaryField] | number }}">
  {{ dataItem[column.primaryField] | number: '1.2-2' }}
</span>
<span *ngIf="column.type == 'integer'" title="{{ dataItem[column.primaryField] | number }}">
  {{ dataItem[column.primaryField] | number: '1.0-0' }}
</span>
<div
  *ngIf="column.type == 'optionSet'"
  title="{{ dataItem[column.primaryField] }}"
  [ngStyle]="{ 'background-color': (dataItem | optionSetColor: column) }">
  {{ dataItem | optionSet: column }}
</div>
<span *ngIf="column.type == 'time'" title="{{ dataItem | time: column }}">
  {{ dataItem | time: column }}
</span>
<span *ngIf="column.type == 'hyperlink'" class="hyperlink-column" title="{{ dataItem[column.primaryField] }}">
  <a
    *ngIf="dataItem | hyperlink: column as hyperlink"
    [appHyperlink]="hyperlink.url"
    [linkBehavior]="column.linkBehavior">
    {{ hyperlink.placeholder }}
  </a>
</span>
<span
  *ngIf="column.isClickable && (column.type == 'text' || column.type == 'guid' || column.type == null)"
  class="lookup-column"
  title="{{ dataItem[column.primaryField] }}">
  <span class="lookup-text" (click)="emitCellClick($event)">
    {{ dataItem | lookup: column }}
  </span>
</span>
<span
  *ngIf="!column.isClickable && (column.type == 'text' || column.type == 'guid' || column.type == null)"
  title="{{ dataItem[column.primaryField] }}">
  {{ dataItem | lookup: column }}
</span>
<span *ngIf="column.type == 'progress' && !column.isOptionSet" title="{{ dataItem[column.primaryField] }}">
  <app-progress-bar
    [min]="column.progressMinValue"
    [max]="column.progressMaxValue"
    [value]="dataItem[column.primaryField]"></app-progress-bar>
</span>
<span *ngIf="column.type == 'progress' && column.isOptionSet" title="{{ dataItem[column.primaryField] }}">
  <app-optionset-progress-bar
    [optionSet]="column.optionSet"
    [min]="column.progressMinValue"
    [max]="column.progressMaxValue"
    [value]="dataItem[column.primaryField]">
  </app-optionset-progress-bar>
</span>
<span *ngIf="column.type == 'rating' && !column.isOptionSet" title="{{ dataItem[column.primaryField] }}">
  <app-rating [value]="dataItem[column.primaryField]" [stars]="column | ratingOptions"></app-rating>
</span>
<span *ngIf="column.type == 'rating' && column.isOptionSet" title="{{ dataItem[column.primaryField] }}">
  <app-rating [value]="dataItem | optionSetValue: column" [stars]="column | ratingOptions"></app-rating>
</span>
<span *ngIf="column.type == 'icon'" title="{{ dataItem | iconTitle: column }}">
  <app-img-icon
    [url]="dataItem | iconUrl: column"
    [iconMode]="column.iconMode"
    [icon]="dataItem | icon: column"
    [size]="column.width ? 'custom' : 'md'"
    [style]="{ 'align-items': column.alignItems }">
  </app-img-icon>
</span>
<span *ngIf="!column.isClickable && column.type == 'combinedTexts'" [title]="dataItem | combinedTexts: column">
  {{ dataItem | combinedTexts: column }}
</span>
<span
  *ngIf="column.isClickable && column.type == 'combinedTexts'"
  [title]="dataItem | combinedTexts: column"
  class="lookup-column">
  <span class="lookup-text" (click)="emitCellClick($event, column.primaryField)">
    {{ dataItem | lookup: column:column.primaryField }}
  </span>
  <span *ngIf="(dataItem | lookup: column:column.primaryField) && (dataItem | lookup: column:column.secondaryField)">
    {{ column.combinedTextsSeparator ?? ', ' }}
  </span>
  <span class="lookup-text" (click)="emitCellClick($event, column.secondaryField)">
    {{ dataItem | lookup: column:column.secondaryField }}
  </span>
</span>
