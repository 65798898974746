import { NgModule } from "@angular/core";
import { QueryBuilderRuleComponent } from "./query-builder-rule/query-builder-rule.component";
import { QueryBuilderComponent } from "./query-builder.component";
import { UiComponentsModule } from "../../shared/ui-components/ui-components.module";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [
    QueryBuilderRuleComponent,
    QueryBuilderComponent,
  ],
  imports: [UiComponentsModule, ReactiveFormsModule, CommonModule],
  exports: [QueryBuilderComponent],
})
export class QueryBuilderModule {}
