import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { Aggregation, Aggregations, AggregationType, GridData, GridItems } from '@shared/grid/models/grid-data.model';
import { HttpClient } from '@angular/common/http';
import { Aggregator, AttributeType, EntityViewDto } from '@core/services/api-clients';
import { CompositeFilterDescriptor, FilterDescriptor, isCompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Injectable, InjectionToken } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { GridDefinitionBuilder } from './grid-definition-builder.service';
import { environment } from '../../../../../environments/environment';
import { serializeSelectExpand } from '@shared/utils';
import { ODataState } from '@shared/odata/models/odata-state.model';
import { ODataStateService } from '@shared/odata/services/odata-state.service';
import { LodashService } from '@core/services/lodash.service';

// TODO: To by można było jakoś ładnie też zrefaktoryzować, poubierać w funkcje itp.

export const ENGINE_VIEW_DATA_PROVIDER = new InjectionToken<EngineViewDataProvider>('EngineViewDataProvider');

class ContextFilter {
  keyword: string;

  constructor(keyword: string) {
    this.keyword = keyword;
  }

  public toString = (): string => this.keyword;
}

@Injectable()
export class EngineViewDataProvider extends BehaviorSubject<GridData> {
  private BASE_URL = environment.urls.ODataUrl;
  public loading: boolean;

  constructor(private _http: HttpClient) {
    super({ data: [], total: 0, aggregations: {} });
  }

  public query(entityView: EntityViewDto, state: ODataState) {
    forkJoin([this.fetch(entityView, state), this.fetchAggregations(entityView, state)])
      .pipe(
        tap(([items, aggregation]) => {
          super.next({ ...items, aggregations: aggregation });
        }),
      )
      .subscribe();
  }

  protected fetch(entityView: EntityViewDto, state: ODataState): Observable<GridItems> {
    const odataQueryString = this.getODataQueryString(entityView, state);

    this.loading = true;

    const baseUrl = `${this.BASE_URL}/${entityView.view.entity.name}`;

    return this._http.get(`${baseUrl}?${odataQueryString}`).pipe(
      map(
        (response) =>
          <GridItems>{
            data: response['value'],
            total: parseInt(response['@odata.count'], 10),
          },
      ),
      tap(() => (this.loading = false)),
    );
  }

  protected fetchAggregations(entityView: EntityViewDto, state: ODataState): Observable<Aggregations> {
    let aggregations = this.getAggregations(entityView);
    if (aggregations && aggregations.length > 0) {
      let filter = this.toODataQueryString({ filter: state.filter });
      if (filter) {
        filter = filter
          .split('=')[1]
          .replace(/'([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})'/gi, '$1');
        filter = `filter(${decodeURIComponent(filter)})/`;
      }

      let aggregationsPart = aggregations
        .map((x) =>
          x.type != 'count'
            ? `${x.primaryField} with ${this.getAggregationType(x.type)} as ${this.getUniqueAggregationId(x)}`
            : `${this.getAggregationType(x.type)} as ${this.getUniqueAggregationId(x)}`,
        )
        .join(',');

      let queryStr = `$apply=${filter}aggregate(${aggregationsPart})`;

      const baseUrl = `${this.BASE_URL}/${entityView.view.entity.name}`;

      return this._http.get(`${baseUrl}?${queryStr}`).pipe(
        map((response: any) => response.value[0]),
        tap((result: { [fieldName: string]: number }) => {
          aggregations.forEach((x) => (x.value = result ? result[this.getUniqueAggregationId(x)] : null));
        }),
        tap(() => (this.loading = false)),
        map(() => LodashService.groupBy(aggregations, 'columnId')),
      );
    }

    return of({});
  }

  private getAggregations(entityView: EntityViewDto): Aggregation[] {
    let aggregationsInfo: Aggregation[] = [];

    for (let column of entityView.view.columns) {
      if (column.availableAggregators && column.availableAggregators.length > 0) {
        for (let aggregation of column.availableAggregators) {
          let aggregationInfo = <Aggregation>{
            name: Aggregator[aggregation],
            columnId: GridDefinitionBuilder.getNormalizedColumnId(column),
            primaryField: column.primaryAttributeName,
            type: GridDefinitionBuilder.mapAggregator(aggregation),
          };

          aggregationsInfo.push(aggregationInfo);
        }
      }
    }

    return aggregationsInfo;
  }

  private getAggregationType(type: AggregationType): string {
    switch (type) {
      case 'average':
        return 'average';
      case 'count':
        return '$count';
      case 'max':
        return 'max';
      case 'min':
        return 'min';
      case 'sum':
        return 'sum';
      case 'countDistinct':
        return 'countdistinct';
    }
  }

  private getUniqueAggregationId(aggregation: Aggregation): string {
    return `${aggregation.primaryField}_${aggregation.type}`;
  }

  // query serializations

  protected getODataQueryString(entityView: EntityViewDto, state: ODataState): string {
    const guidColumns = this.getGuidColumns(entityView, state);
    this.fixGuidFilters(guidColumns, state.filter);

    let odataQueryString = `${this.toODataQueryString(state)}&$count=true`;

    // Mała poprawka zgodności wersji OData Edm/String -> Edm.String
    odataQueryString = odataQueryString.replace(/Edm\/String/g, 'Edm.String');

    const guidColumnsRegex = guidColumns.map((x) => x.replace(/\./g, '/')).join('|');
    odataQueryString = odataQueryString.replace(
      new RegExp(`(${guidColumnsRegex}) eq '([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})'`, 'ig'),
      '$1 eq $2',
    );
    odataQueryString = odataQueryString.replace(
      new RegExp(`contains\\((${guidColumnsRegex})\\,\\'(.*)\\'\\)`, 'ig'),
      "contains(cast($1, 'Edm.String'),'$2')",
    );

    let expandPart = serializeSelectExpand(entityView.view.viewAttributes);
    if (expandPart) odataQueryString += `&${expandPart}`;

    return odataQueryString;
  }

  private getGuidColumns(entityView: EntityViewDto, state: ODataState): string[] {
    const guidColumns = entityView.view.entity.attributes
      .filter((x) => x.type == AttributeType.Guid)
      .map((x) => x.name);

    const lookupColumns = entityView.view.entity.attributes
      .filter((x) => x.type == AttributeType.Lookup)
      .map((x) => x.name + 'Id');

    guidColumns.push(...lookupColumns);

    // Jeżeli w filtrze znajduje się jakieś pole, które kończy się na "Id"
    // i nie pochodzi z encji bazowej (zawiera kropkę) to potraktuj je jako GUID
    const filterColumns = this.getAllFieldsFromFilter(state.filter);
    guidColumns.push(
      ...filterColumns.filter((filterColumn) => filterColumn.endsWith('Id') && filterColumn.indexOf('.') !== -1),
    );

    return guidColumns;
  }

  private getAllFieldsFromFilter(compositeFilter: CompositeFilterDescriptor): string[] {
    let fields: string[] = [];

    if (compositeFilter) {
      for (const filter of compositeFilter.filters) {
        if (filter) {
          if (isCompositeFilterDescriptor(filter)) {
            fields.push(...this.getAllFieldsFromFilter(filter));
          } else {
            fields.push(filter.field as string);
          }
        }
      }
    }

    return [...new Set(fields)];
  }

  private fixGuidFilters(guidColumns: string[], filter: CompositeFilterDescriptor) {
    if (filter && filter.filters && filter.filters.length > 0) {
      for (let subfilter of filter.filters) {
        if (subfilter['logic']) {
          this.fixGuidFilters(guidColumns, subfilter as CompositeFilterDescriptor);
        } else {
          const fieldFilter = subfilter as FilterDescriptor;
          const isGuidFilter = guidColumns.findIndex((x) => x == fieldFilter.field) > -1;

          if (isGuidFilter) {
            const isContextFilter = fieldFilter.value != null && fieldFilter.value.keyword != undefined;

            if (!isContextFilter) {
              const isValidGuid =
                fieldFilter.value == null ||
                /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/.test(fieldFilter.value);

              if (!isValidGuid) {
                fieldFilter.operator = 'contains';
              }
            }
          }
        }
      }
    }
  }

  private toODataQueryString(state: ODataState): string {
    let customQuery: string = '';
    const currentState: ODataState = LodashService.cloneDeep(state);

    if (state && state.filter && state.filter.filters) {
      const queryItems = state.filter.filters.map((x) => this.createQueryItems(x));
      currentState.filter.filters = state.filter.filters.filter((_, index) => !queryItems[index].isCustom);
      customQuery = queryItems
        .filter((x) => x.isCustom)
        .map((x) => x.query)
        .join(` ${state.filter.logic} `);
    }

    this.replaceFiltersKeywords(currentState.filter);

    let queryString = ODataStateService.toODataString(currentState);

    if (customQuery) {
      if (queryString.includes('$filter=')) {
        queryString = queryString.replace('$filter=', `$filter=${customQuery} and `);
      } else {
        queryString = queryString.concat(`&$filter=${customQuery}`);
      }
    }
    return queryString;
  }

  private createQueryItems(filter: CompositeFilterDescriptor | FilterDescriptor): {
    query: string;
    isCustom: boolean;
  } {
    const customOperators: string[] = ['has', 'any'];
    let query = null;
    let isCustom = false;

    if ('filters' in filter) {
      const queryItems = filter.filters.map((x) => this.createQueryItems(x));
      isCustom = queryItems.findIndex((x) => x.isCustom) !== -1;
      query = queryItems.map((x) => x.query).join(` ${filter.logic} `);
      if (query && filter.logic === 'or') {
        query = `(${query})`;
      }
    } else {
      if (
        customOperators.includes(filter.operator as string) ||
        (!!filter['baseType'] && filter['baseType'] == 'duration')
      ) {
        isCustom = true;
        query = `${filter.field} ${filter.operator} ${filter.value}`;
      } else {
        query = ODataStateService.toODataString({
          filter: {
            filters: [filter],
            logic: 'and'
          }
        }).replace("$filter=", "");
      }
    }
    return { query, isCustom };
  }

  private replaceFiltersKeywords(filter: CompositeFilterDescriptor) {
    if (filter && filter.filters) {
      filter.filters.forEach((x) => {
        if ('value' in x) {
          if (x.value && x.value.keyword !== undefined) x.value = new ContextFilter(x.value.keyword);
        } else if ('filters' in x) {
          this.replaceFiltersKeywords(x);
        }
      });
    }
  }
}
