import {
  Component,
  Input,
  ChangeDetectionStrategy,
  forwardRef,
  Injector,
  Output,
  EventEmitter,
  Inject,
  OnDestroy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { BaseFormControlDirective } from '@shared/reactive-controls/directives/base-form-control.directive';
import { CULTURE_SERVICE, ICultureService } from '@shared/reactive-controls/models/iculture-service.model';
import { IAutocompleteOption, IAutocompleteActionOption } from './autocomplete-control.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-autocomplete-control',
  templateUrl: './autocomplete-control.component.html',
  styleUrls: ['./autocomplete-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: AutocompleteControlComponent,
    },
  ],
})
export class AutocompleteControlComponent extends BaseFormControlDirective implements OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _options: IAutocompleteOption[] = [];
  private _valuesToLabels: { [value: string]: string } = {};

  @Input()
  set options(v: IAutocompleteOption[]) {
    this._options = v;
    v.forEach((o) => (this._valuesToLabels[o.value] = o.label));

    if (this._options.length && !!this.formControl.value) {
      this.syncLabelInputWithValueInput();
    }
  }

  @Input() override set isRequired(v: boolean) {
    super.isRequired = v;
    this.setLabelInputValidators();
  }

  @Input() actionOptions: IAutocompleteActionOption[] = [];
  @Input() isCreateButtonVisible: boolean;
  @Input() isOpenButtonVisible: boolean;
  @Input() isUpdateButtonVisible: boolean;

  @Output() changeValue = new EventEmitter<IAutocompleteOption>();
  @Output() filterChange = new EventEmitter<string>();
  @Output() editClick = new EventEmitter<void>();
  @Output() addClick = new EventEmitter<void>();

  labelFormControl: UntypedFormControl;
  get options(): IAutocompleteOption[] {
    return this._options;
  }
  override get isRequired() {
    return super.isRequired;
  }

  constructor(@Inject(CULTURE_SERVICE) cultureService: ICultureService, injector: Injector) {
    super(cultureService, injector);
    this.labelFormControl = new UntypedFormControl({
      value: { value: this.formControl.value, label: '' },
      disabled: this.formControl.disabled,      
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  override writeValue(value: any): void {
    if (this.formControl.value !== value) {
      this.formControl.setValue(value);
      this.syncLabelInputWithValueInput();
    }
  }

  override setDisabledState(isDisabled: boolean): void {
    if (isDisabled == this.formControl.disabled) return;
    isDisabled ? this.formControl.disable() : this.formControl.enable();
    isDisabled ? this.labelFormControl.disable() : this.labelFormControl.enable();
  }

  override onBlur(): void {
    super.onBlur();
    const shouldCleanUpLabel = !this.formControl.value && this.labelFormControl.value != '';
    if (shouldCleanUpLabel) {
      this.labelFormControl.patchValue('');
      this.filterChange.emit('');
    }
  }

  onFilterChange(event: KeyboardEvent): void {
    const calculatedLabel = this.formControl.value ? this._valuesToLabels[this.formControl.value] : '';
    const target: HTMLInputElement = <any>event.target;
    const isLabelAndValueMatch = target.value == calculatedLabel;
    if (!isLabelAndValueMatch) {
      this.formControl.markAsDirty();
      this.formControl.markAsTouched();
      this.formControl.patchValue(null);
    }
    this.filterChange.emit(target.value);
  }

  onOptionSelected(event: any): void {
    const actionOption = this.tryGetActionOptionByValue(event?.option?.value?.value);
    if (!!actionOption) {
      this.syncLabelInputWithValueInput();
      actionOption.handler();
      return;
    }

    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
    this.formControl.patchValue(event.option?.value?.value ?? null);
  }

  onClearClick(): void {
    if (this.formControl.disabled) return;
    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
    this.formControl.patchValue(null);
    this.labelFormControl.patchValue(null);
  }

  onEditClick(event: MouseEvent): void {
    this.editClick.emit();
  }

  onAddClick(): void {
    if (this.formControl.disabled) return;
    this.addClick.emit();
  }

  displayFn(option: IAutocompleteOption): string {
    if (!option) return '';
    return option.label;
  }

  setCreateButtonVisibility(visibility: boolean) {
    this.isCreateButtonVisible = visibility;
    this._changeDetector.markForCheck();
  }

  setOpenButtonVisibility(visibility: boolean) {
    this.isOpenButtonVisible = visibility;
    this._changeDetector.markForCheck();
  }

  setUpdateButtonVisibility(visibility: boolean) {
    this.isUpdateButtonVisible = visibility;
    this._changeDetector.markForCheck();
  }

  private tryGetActionOptionByValue(optionValue: any): IAutocompleteActionOption {
    return this.actionOptions.find((b) => b.id == optionValue) ?? undefined;
  }

  private syncLabelInputWithValueInput() {
    this.labelFormControl.patchValue({
      value: this.formControl.value,
      label: this._valuesToLabels[this.formControl.value],
    });
  }

  private setLabelInputValidators(): void {
    if (!this.labelFormControl) return;
    this.labelFormControl.setValidators(Object.values(this._validators).filter((v) => !!v));
    this.labelFormControl.updateValueAndValidity({ emitEvent: true });
  }
}
