import { Pipe, PipeTransform } from "@angular/core";
import { GridColumn } from "../models/grid-definition.model";

@Pipe({
  name: 'optionSetColor',
})
export class OptionSetColorPipe implements PipeTransform {
  transform(dataItem: any, column: GridColumn): string {
    if (!dataItem || !dataItem[column.primaryField]) return;

    const option = column.optionSet.values.find((e) => e.name === dataItem[column.primaryField]);
    return option && option.color ? option.color : '';
  }
}
