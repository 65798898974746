export enum ExecutionEventType {
  OnClick = 0,
  OnChanged = 1,
  OnSaving = 2,
  OnLoaded = 3,
  PreFetch = 4,
}

export interface IExecutionEvent {
  eventType: ExecutionEventType;
  data?: any;
}

export interface ISavingExecutionEvent extends IExecutionEvent {
  suppressSaving(): void;
  saveAsync(): void;
}

export interface IPreFetchExecutionEvent extends IExecutionEvent {
  suppressFetch(): void;
  fetchAsync(): void;
}
