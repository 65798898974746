import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const METADATA_PROVIDER_SERVICE = new InjectionToken<IMetadataProvider>('IMetadataProvider');

export type FieldType =
  | 'text'
  | 'number'
  | 'yesno'
  | 'date'
  | 'choice'
  | 'guid';

export interface IEntityAttribute {
  id: string;
  name: string;
  type: FieldType;
  targetEntityId?: string;
}

export interface IEntity {
  id: string;
  name: string;
  fields: IEntityAttribute[];
}

export interface IMetadataProvider {
  getMetadata(): Observable<IEntity[]>;
}
