import { Injectable } from '@angular/core';
import { CombinedLookupDto } from '@core/services/api-clients';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';
import { HttpClient, HttpContext } from '@angular/common/http';
import { environment } from '@env';
import { PreFetchExecutionEvent } from '@core/execution-context';
import { ExecutionEventType } from '../../../engine-sdk';
import { ODataState } from '@shared/odata/models/odata-state.model';
import { ODataStateService } from '@shared/odata/services/odata-state.service';
import { IGNORE_BUSY_INDICATOR } from '@core/layout/components/busy-indicator/busy-indicator.interceptor';
import { LodashService } from '@core/services/lodash.service';
import {
  IGroupedAutocompleteOption,
  IGroupedAutocompleteOptions,
} from '@shared/reactive-controls/components/grouped-autocomplete/grouped-autocomplete-control.model';

export interface ICombinedLookupsServiceState {
  value: any;
  searchText: string;
  odataQuery: ODataState;
  lookups: CombinedLookupDto[];
}

@Injectable()
export class EngineCombinedLookupsService {
  private readonly BASE_URL = environment.urls.ODataUrl;
  private _stateBs: BehaviorSubject<ICombinedLookupsServiceState> = new BehaviorSubject({
    value: undefined,
    searchText: undefined,
    odataQuery: {
      take: 50,
      filter: <CompositeFilterDescriptor>{
        logic: 'and',
        filters: [],
      },
    },
    lookups: [],
  });
  private _groupedOptionsBs: BehaviorSubject<IGroupedAutocompleteOptions> = new BehaviorSubject({});
  private _preFetch: Subject<PreFetchExecutionEvent> = new Subject();

  state$ = this._stateBs.asObservable().pipe(distinctUntilChanged());
  groupedOptions$ = this._groupedOptionsBs.asObservable();
  preFetch$ = this._preFetch.asObservable();

  get currentState() {
    return this._stateBs.value;
  }
  get odataQuery() {
    return this.currentState.odataQuery;
  }

  constructor(private _http: HttpClient) {
    this.state$
      .pipe(
        filter((state) => !!state.lookups?.length),
        debounceTime(500),
        tap((state) =>
          this._preFetch.next(
            new PreFetchExecutionEvent(ExecutionEventType.PreFetch, () =>
              this.fetchGroupedOptions(state.value, state.searchText),
            ),
          ),
        ),
      )
      .subscribe();
  }

  init(lookups: CombinedLookupDto[], value: any) {
    if (!lookups.length) return;
    this._stateBs.next({ ...this.currentState, lookups, value });
  }

  selectOption(value: any) {
    this._stateBs.next({ ...this.currentState, value, searchText: undefined });
  }

  clearOptionSelection() {
    this._stateBs.next({ ...this.currentState, value: null });
  }

  search(text: string) {
    if (this.currentState.searchText == text || !!this.currentState.value) return;
    this._stateBs.next({ ...this.currentState, searchText: text });
  }

  addFilter(filter: CompositeFilterDescriptor) {
    const newState = LodashService.cloneDeep(this.currentState) as ICombinedLookupsServiceState;
    newState.odataQuery.filter.filters.push(filter);
    this._stateBs.next(newState);
  }

  setFilter(filter: CompositeFilterDescriptor) {
    const newState = LodashService.cloneDeep(this.currentState) as ICombinedLookupsServiceState;
    newState.odataQuery.filter = filter;
    this._stateBs.next(newState);
  }

  setCustomFilter(filter: string) {
    const newState = LodashService.cloneDeep(this.currentState) as ICombinedLookupsServiceState;
    newState.odataQuery.customFilter = filter;
    this._stateBs.next(newState);
  }

  private fetchGroupedOptions(value: string, searchText: string): void {
    combineLatest(
      this.currentState.lookups.map((l) =>
        this.getOptionsAsync(l.attributeName, value ? value[l.attributeName] : undefined, searchText),
      ),
    )
      .pipe(
        tap((responses) => {
          const attributes = this.currentState.lookups.map((x) => x.attributeName);
          const newValue = {};
          responses.forEach((x, index) => (newValue[attributes[index]] = x));
          this._groupedOptionsBs.next(newValue);
        }),
      )
      .subscribe();
  }

  private getOptionsAsync(
    lookupId: string,
    selectedId: string,
    searchText: string,
  ): Observable<IGroupedAutocompleteOption[]> {
    if (!selectedId == undefined && searchText == undefined) return of([]);
    const lookup = this.currentState.lookups.find((l) => l.attributeName == lookupId);
    const searchState = selectedId
      ? this.getQueryByRecordId(selectedId)
      : this.getQueryBasedOnCurrentFiltersAndSearchTerm(searchText, lookup.showField);

    return this.query(lookup.targetEntity.name, lookup.showField, searchState).pipe(first());
  }

  private getQueryBasedOnCurrentFiltersAndSearchTerm(searchTerm: string, showfield: string): ODataState {
    const currentState = LodashService.cloneDeep(this.currentState.odataQuery);
    if (searchTerm) {
      currentState.filter.filters.push({
        field: showfield,
        operator: 'contains',
        value: searchTerm,
      });
    }

    return currentState;
  }

  private getQueryByRecordId(recordId: string): ODataState {
    if (!this.isGuid(recordId))
      throw {
        error: 'It is not possible to get the record into lookup because record id is invalid',
      };

    return {
      take: 1,
      skip: 0,
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'Id',
            operator: 'eq',
            value: recordId,
          },
        ],
      },
    };
  }

  private query(entityName: string, showField: string, state: ODataState): Observable<IGroupedAutocompleteOption[]> {
    let url = `${this.BASE_URL}/${entityName}?${ODataStateService.toODataString(
      state,
    )}& $select=Id, ${showField}& $orderby=${showField}`;

    url = url.replace(/'([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})'/gi, '$1');

    const context = new HttpContext();
    context.set(IGNORE_BUSY_INDICATOR, true);
    return this._http.get(`${url}`, { context }).pipe(
      map((res) => res['value']),
      map((data) =>
        data.map((item) => {
          return { value: item.Id, label: item[showField] };
        }),
      ),
    );
  }

  private isGuid(string: string): boolean {
    if (string[0] === '{') string = string.substring(1, string.length - 1);

    const regexGuid =
      /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(string);
  }
}
