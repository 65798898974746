import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainKanbanComponent } from './main-kanban/main-kanban.component';
import { MainFormComponent } from './main-form/main-form.component';
import { MainViewComponent } from './main-view/main-view.component';
import { B2CRedirectComponent } from '@core/auth/components/b2c-redirect.component';
import { AuthGuard } from '@core/auth/guards/auth.guard';
import { NavigateByNavigationPathGuard } from '@core/navigation/guards/navigate-by-navigation-path.guard';
import { ReplaceUrlToTokenGuard } from '@core/navigation/guards/replace-url-to-token.guard';
import { TestEnvOnlyGuard } from '@core/navigation/guards/test-env-only.guard';
import { TestFormComponent } from './test-form/test-form.component';

const routes: Routes = [
  {
    path: 'auth',
    component: B2CRedirectComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'test-form',
        component: TestFormComponent,
        pathMatch: 'full',
        canActivate: [TestEnvOnlyGuard],
      },
      {
        path: 'entity/:entityId/record/:recordId',
        redirectTo: 'entity/:entityId/record/:recordId/form/',
        pathMatch: 'full',
      },
      {
        path: 'form/:formId',
        component: MainFormComponent,
        canActivate: [ReplaceUrlToTokenGuard],
      },
      {
        path: 'entity/:entityId/record/:recordId/form/:formId',
        component: MainFormComponent,
        canActivate: [ReplaceUrlToTokenGuard],
      },
      {
        path: 'entity/:entityId',
        redirectTo: 'entity/:entityId/view/',
        pathMatch: 'full',
      },
      {
        path: 'entity/:entityId/view/:viewId',
        component: MainViewComponent,
        canActivate: [ReplaceUrlToTokenGuard],
      },
      {
        path: 'entity/:entityId/kanban/:kanbanId',
        component: MainKanbanComponent,
        canActivate: [ReplaceUrlToTokenGuard],
      },
      {
        path: 'entity/:entityId/form',
        redirectTo: 'entity/:entityId/form/',
        pathMatch: 'full',
      },
      {
        path: 'entity/:entityId/form/:formId',
        component: MainFormComponent,
        canActivate: [ReplaceUrlToTokenGuard],
      },
      {
        path: ':navigationPath',
        children: [],
        canActivate: [NavigateByNavigationPathGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
