import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf,
} from '@angular/core';
import { CompositeFilterDescriptor, FilterDescriptor, isCompositeFilterDescriptor } from '@progress/kendo-data-query';
import { NoEventWidgetDirective } from '@core/widgets/directives/no-event-widget.directive';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { GridDefinitionBuilder } from './services/grid-definition-builder.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IEntityInfo, IViewContext } from '../../../../engine-sdk';
import { ContextMenuDto, EntityViewDto } from '../../../../core/services/api-clients';
import {
  ColumnResizeEvent,
  LookupColumnClickEvent,
  RowDoubleClickEvent,
  StateChangeEvent,
} from '../../models/view.model';
import { GridDefinition } from '@shared/grid/models/grid-definition.model';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '../../../../core/widgets/models/iscript-runner.service';
import { ContextService } from '../../../../core/services/context.service';
import { CellClickEvent, ColumnResizeEvent as GridColumnResizeEvent } from '@shared/grid/models/grid-events.model';
import { GuidService } from '../../../../core/services/guid.service';
import { WidgetType } from '../../../../core/widgets/models/widget-type';
import { EngineViewDataProvider, ENGINE_VIEW_DATA_PROVIDER } from './services/engine-view-data-provider.service';
import { GridSettings } from './services/engine-view-settings.service';
import {
  EngineViewStateService,
  ENGINE_VIEW_STATE_SERVICE,
  UrlState,
  EngineFilterType,
} from './services/engine-view-state.service';
import { ODataState } from '@shared/odata/models/odata-state.model';
import { tap, takeUntil, map } from 'rxjs/operators';
import { UserInteractionService } from '@core/services/user-interaction.service';
import { UserInteractionEventType } from 'src/engine-sdk/contract/user-interaction';
import {
  ENGINE_DATA_CONTEXT_PROVIDER,
  IEngineDataContextProvider,
  IEngineViewDataContext,
} from 'src/engine-sdk/contract/engine-data-context';
import { SelectableMode } from '@progress/kendo-angular-grid';
import { LodashService } from '@core/services/lodash.service';
import moment from 'moment';

@Component({
  selector: 'app-engine-view',
  templateUrl: './engine-view.component.html',
  styleUrls: ['./engine-view.component.scss'],
  providers: [
    {
      provide: WidgetDirective,
      useExisting: forwardRef(() => EngineViewComponent),
    },
    {
      provide: ENGINE_VIEW_DATA_PROVIDER,
      useClass: EngineViewDataProvider,
    },
    {
      provide: ENGINE_DATA_CONTEXT_PROVIDER,
      useExisting: forwardRef(() => EngineViewComponent),
    },
  ],
})
export class EngineViewComponent
  extends NoEventWidgetDirective
  implements IViewContext, IEngineDataContextProvider, OnChanges, OnDestroy
{
  private _entityView: EntityViewDto;
  private _selectedKeys = new BehaviorSubject<string[]>([]);
  private _isDataFetchingEnabled = true;
  private _selectionMode: SelectableMode | 'none';

  @Input() set entityView(v: EntityViewDto) {
    this._entityView = v;
    if (this.entityView) {
      this.widgetId = this.entityView.view.id;
    }
  }

  @Input() settings: GridSettings;
  @Input() urlState: UrlState;

  @Input() contextMenu: ContextMenuDto;
  @Input() isReadOnly: boolean = true;

  // The max rows count displayed at the same time,
  // If data.items.count > displayRows, a scroll bar is visible
  @Input() displayRows: number;
  @Input() selectedKeys: string[];

  @Input() set selectionMode(input: SelectableMode | 'none') {
    if (this._selectionMode != input) {
      this._selectionMode = input;

      if (this.entityView && this.settings) {
        this.gridDefinition = GridDefinitionBuilder.build(this.entityView, this.settings, this.selectionMode);
        this._changeDetector.markForCheck();
      }
    }
  }

  get selectionMode(): SelectableMode | 'none' {
    return this._selectionMode;
  }

  @Input() set isDataFetchingEnabled(isEnabled: boolean) {
    if (this._isDataFetchingEnabled != isEnabled) {
      this._isDataFetchingEnabled = isEnabled;

      if (this._isDataFetchingEnabled) {
        this.refreshData();
      }
    }
  }

  @Output() rowDoubleClick = new EventEmitter<RowDoubleClickEvent>();
  @Output() lookupColumnClick = new EventEmitter<LookupColumnClickEvent>();
  @Output() columnResize = new EventEmitter<ColumnResizeEvent>();
  @Output() stateChange = new EventEmitter<StateChangeEvent>();
  @Output() pageSizeChange = new EventEmitter<number>();

  gridDefinition: GridDefinition;
  gridQuery$ = this.stateService.getViewStateAsync().pipe(
    map((state) => LodashService.cloneDeep(state.userQuery)),
    map((state: ODataState) => {
      if (state.filter?.filters?.length) {
        const gridFilters = state.filter;
        gridFilters.filters = gridFilters.filters.filter((f: any) => !f[EngineFilterType.GlobalSearch]);
        state.filter = gridFilters;
      }
      return state;
    }),
  );

  get isDataFetchingEnabled() {
    return this._isDataFetchingEnabled;
  }
  get entityView(): EntityViewDto {
    return this._entityView;
  }

  isNavigationOnDoubleClickEnabled: boolean = true;

  constructor(
    @Inject(ENGINE_VIEW_DATA_PROVIDER) public dataProvider: EngineViewDataProvider,
    @Inject(ENGINE_VIEW_STATE_SERVICE) public stateService: EngineViewStateService,
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    @Optional()
    @SkipSelf()
    @Inject(ENGINE_DATA_CONTEXT_PROVIDER)
    private _engineDataContextProvider: IEngineDataContextProvider,
    private _userInteractionService: UserInteractionService,
    private _contextService: ContextService,
    private _changeDetector: ChangeDetectorRef,
  ) {
    super(parentWidget, scriptRunnerService);

    if (this.isOnForm()) {
      this._contextService.registerView(this);
    } else {
      this._contextService.registerMainView(this);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['entityView']) {
      if (this.entityView && this.settings) {
        this.gridDefinition = GridDefinitionBuilder.build(this.entityView, this.settings, this.selectionMode);
        this.stateService.initialize(this.gridDefinition, this.urlState);
        this.refreshData();
        this._changeDetector.markForCheck();
      }
    } else if (changes['settings']) {
      if (this.entityView && this.settings) {
        this.gridDefinition = GridDefinitionBuilder.build(this.entityView, this.settings, this.selectionMode);
        this.stateService.initialize(this.gridDefinition, this.stateService.getViewState().urlState);
      }
    }
  }

  ngOnDestroy() {
    this._userInteractionService.clearFeed(this.widgetId);
    if (this.isOnForm()) {
      this._contextService.unregisterView(this);
    } else {
      this._contextService.unregisterMainView(this);
    }

    super.ngOnDestroy();
  }

  onStateChange(state: ODataState) {
    this.stateService.setState(state);
    this.refreshData();

    this.stateChange.emit({
      viewId: this.entityView.view.id,
      urlState: this.stateService.getViewState().urlState,
    });
  }

  onSelectedKeysChange(keys: string[]) {
    this._selectedKeys.next(keys);
  }

  onColumnResize(event: GridColumnResizeEvent) {
    this.columnResize.emit({
      viewId: this.entityView.view.id,
      columnId: event.column.columnId,
      width: event.newWidth,
    });
  }

  emitLookupColumnClick(event: CellClickEvent) {
    this.lookupColumnClick.emit({
      dataItem: event.dataItem,
      column: this.entityView.view.columns.find(
        (c) => GridDefinitionBuilder.getNormalizedColumnId(c) == event.column.columnId,
      ),
      attributeName: event.fieldName,
    });
  }

  getDataContext(): IEngineViewDataContext {
    const parentDataContext = this._engineDataContextProvider ? this._engineDataContextProvider.getDataContext() : {};
    return {
      ...parentDataContext,
      viewEntityId: this.entityView?.view?.entity?.id,
      viewEntityName: this.entityView?.view?.entity?.name,
      viewId: this.entityView?.view?.id,
      viewName: this.entityView?.view?.name,
    };
  }

  //#region WidgetDirective
  override onLoaded(): void {
    super.onLoaded();
    this._userInteractionService.feed({
      providerId: this.widgetId,
      context: {
        ...this.getDataContext(),
        eventType: UserInteractionEventType.viewLoaded,
      },
      value: true,
    });
    this.selectionChanged()
      .pipe(
        takeUntil(this._destroy$),
        tap((x) => {
          this._userInteractionService.feed({
            providerId: this.widgetId,
            context: {
              ...this.getDataContext(),
              eventType: UserInteractionEventType.viewItemSelected,
            },
            value: [...x],
          });
        }),
      )
      .subscribe();
  }
  //#endregion

  //#region IViewContext
  id = GuidService.generateNew();

  getViewId(): string {
    return this.entityView.view?.id;
  }

  getDefaultForm(): string {
    return this.entityView?.defaultFormId;
  }

  getEntityInfo(): IEntityInfo {
    return {
      entityId: this.entityView?.view?.entity?.id,
      entityName: this.entityView?.view?.entity?.name,
    };
  }

  getSelectedRecords(): any[] {
    return this._selectedKeys.value;
  }

  setSelectedRecords(selectedIds: any[]): void {
    this._selectedKeys.next(selectedIds);
    this._changeDetector.markForCheck();
  }

  refreshData(): void {
    if (this.isDataFetchingEnabled) {
      this.dataProvider.query(this.entityView, this.stateService.getViewState().finalQuery);
    }
  }

  setAdditionalFilters(value: CompositeFilterDescriptor): void {
    this.stateService.setAdditionalFilters(value);
  }

  removeAdditionalFilters(): void {
    this.stateService.removeAdditionalFilters();
  }

  getDefaultQuery(): ODataState {
    return this.stateService.getViewState().defaultQuery;
  }

  getAppliedQuery(): ODataState {
    const copy: ODataState = LodashService.cloneDeep(this.stateService.getViewState().finalQuery);
    this.fixDateOnlyFilters(copy?.filter?.filters);

    return copy;
  }

  selectionChanged(): Observable<any[]> {
    return this._selectedKeys.asObservable();
  }

  emitRowDoubleClick(event: CellClickEvent) {
    if (this.isNavigationOnDoubleClickEnabled) {
      this.rowDoubleClick.emit({
        dataItem: event.dataItem,
        entityId: this.entityView.view.entity.id,
        defaultFormId: this.entityView.defaultFormId,
      });
    }
  }
  //#endregion

  private isOnForm() {
    let parentWidget = this.parentWidget;
    while (parentWidget != null) {
      if (parentWidget.getWidgetType() == WidgetType.Form) return true;

      parentWidget = parentWidget.parentWidget;
    }

    return false;
  }

  private fixDateOnlyFilters(filters: Array<FilterDescriptor | CompositeFilterDescriptor>) {
    if (filters) {
      for (const filter of filters) {
        if (filter) {
          if (isCompositeFilterDescriptor(filter)) {
            this.fixDateOnlyFilters(filter.filters);
          } else if (filter.value instanceof Date && this.gridDefinition.columns.find(x => x.primaryField == filter.field)?.type == 'date') {
            filter.value = moment(filter.value).utc(true).format()
          }
        }
      }
    }
  }
}
