import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import {
  AuthenticationResult,
  AuthError,
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { AuthenticationSuccess } from '../store/actions';
import { IAuthService } from '../models/iauth-service.model';
import { AuthSelectors } from '../store';
import { AuthenticationType } from '../models/authentication-type.enum';

@Injectable()
export class AzureADAuthService implements IAuthService, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _isInitialized: boolean = false;

  private static CANCEL_ERROR_CODE: string = 'AADB2C90091';

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private _msalGuardConfig: MsalGuardConfiguration,
    private _broadcastService: MsalBroadcastService,
    private _msalService: MsalService,
    private _store: Store,
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  init(): void {
    if (this._isInitialized) return;
    this._isInitialized = true;
    this.startListeningOnAuthStateChange();
  }

  getToken(): Observable<AuthenticationResult> {
    const accounts = this._msalService.instance.getAllAccounts();
    if (accounts.length == 0) {
      return EMPTY;
    }
    const account = this._msalService.instance.getAllAccounts()[0];
    return this._msalService.acquireTokenSilent({
      account: account,
      scopes: (this._msalGuardConfig.authRequest as RedirectRequest).scopes,
    });
  }

  isUserAuthenticated(): Observable<boolean> {
    return this._store.select(AuthSelectors.isAuthenticated);
  }

  logout() {
    this._msalService.logoutRedirect();
  }

  getCurrentAccount(): any | null {
    const accounts = this._msalService.instance.getAllAccounts();
    return accounts.length > 0 ? (accounts[0] as any) : null;
  }

  goToLoginPage(): void {
    if (this._msalGuardConfig.authRequest) {
      this._msalService.loginRedirect(this._msalGuardConfig.authRequest as RedirectRequest);
    } else {
      this._msalService.loginRedirect();
    }
  }

  getAuthenticationType(): AuthenticationType {
    return AuthenticationType.AzureActiveDirectory;
  }

  private isAuthenticationCompleted(status: InteractionStatus) {
    return status === InteractionStatus.None;
  }

  private isForgotPasswordClicked(result: EventMessage) {
    return false;
  }

  private handleLogin() {
    const account = this.getCurrentAccount();
    if (account !== null) {
      this._store.dispatch(new AuthenticationSuccess());
    }
  }

  private handleError(result: EventMessage) {
    if (result.error instanceof AuthError) {
      if (result.error.message.includes(AzureADAuthService.CANCEL_ERROR_CODE)) {
        this.goToLoginPage();
      } else {
        this.goToLoginPage();
      }
    }
  }

  private startListeningOnAuthStateChange(): void {
    this._broadcastService.msalSubject$
      .pipe(
        takeUntil(this._destroy$),
        tap((result: EventMessage) => {
          switch (result.eventType) {
            case EventType.LOGIN_SUCCESS:
            case EventType.ACQUIRE_TOKEN_SUCCESS:
              if (this.isForgotPasswordClicked(result)) {
                // this._store.dispatch(passwordResetSuccess());
              }
              break;
            case EventType.LOGOUT_SUCCESS:
              // this._store.dispatch(logoutSuccess());
              break;
            case EventType.LOGIN_FAILURE:
            case EventType.ACQUIRE_TOKEN_FAILURE:
              this.handleError(result);
              break;

            default:
              break;
          }
        }),
      )
      .subscribe();

    this._broadcastService.inProgress$
      .pipe(
        takeUntil(this._destroy$),
        filter((status: InteractionStatus) => this.isAuthenticationCompleted(status)),
        tap(() => this.handleLogin()),
      )
      .subscribe();
  }
}
