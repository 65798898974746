import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { IConditionRule, IOperator, IRule } from './query-builder.model'
import { IEntity } from '@core/metadata/imetadata-provider.service'
import { LodashService } from '@core/services/lodash.service';

@Component({
  selector: 'app-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryBuilderComponent {
  private _conditionRule: IConditionRule = {
    condition: 'and',
    rules: [],
    conditionRules: []
  };

  logicOperators: IOperator[] = [
    { text: 'And', value: 'and' },
    { text: 'Or', value: 'or' }
  ];

  @Input() entity: IEntity;

  @Input() set conditionRule(conditionRule: IConditionRule) {
    if (conditionRule) {
      this._conditionRule = conditionRule;
    }
  }
  get conditionRule() {
    return this._conditionRule;
  }

  get fields() {
    return this.entity ? this.entity.fields : [];
  }

  @Output() conditionRuleChange = new EventEmitter<IConditionRule>();

  constructor() {}

  onConditionChange(value: string) {
    this._conditionRule.condition = LodashService.cloneDeep(value);
    this.conditionRuleChange.emit(this._conditionRule);
  }

  addRule() {
    this._conditionRule.rules.push(<IRule>{
      field: null,
      operator: null,
      entity: this.entity.name,
      value: null
    })
    this.conditionRuleChange.emit(this._conditionRule);
  }

  addConditionRule() {
    this._conditionRule.conditionRules.push(<IConditionRule>{
      condition: 'and',
      rules: [],
      conditionRules: []
    })
    this.conditionRuleChange.emit(this._conditionRule);
  }

  onRuleChange(index, value: IRule) {
    this._conditionRule.rules[index] = LodashService.cloneDeep(value);
    this.conditionRuleChange.emit(this._conditionRule);
  }

  onConditionRuleChange(index, value: IConditionRule) {
    this._conditionRule.conditionRules[index] = LodashService.cloneDeep(value);
    this.conditionRuleChange.emit(this._conditionRule);
  }

  remove(index: number) {
    this._conditionRule.rules.splice(index, 1);
    this.conditionRuleChange.emit(this._conditionRule);
  }

  removeConditionRule(index: number) {
    this._conditionRule.conditionRules.splice(index, 1);
    this.conditionRuleChange.emit(this._conditionRule);
  }
}
