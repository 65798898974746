import {
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, tap } from 'rxjs/operators';

@Directive({
  selector: '[appResize]',
})
export class ResizeDirective implements OnInit, AfterViewInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _subject$ = new Subject();

  @Output() windowResized = new EventEmitter<number>();

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    this._subject$
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(100),
        tap(() => this.windowResized.emit(this.elementRef.nativeElement.clientWidth)),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.windowResized.emit(this.elementRef.nativeElement.clientWidth);
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  @HostListener('window:resize')
  onWindowResize() {
    if (this.elementRef) {
      this._subject$.next();
    }
  }
}
