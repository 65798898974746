import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormOption } from '@core/navigation/store/state';

@Component({
  selector: 'app-form-selector',
  templateUrl: './form-selector.component.html',
  styleUrls: ['./form-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSelectorComponent {
  private _forms: FormOption[] = [];

  @Input() set forms(forms: FormOption[]) {
    this._forms = forms?.length ? forms : [];
    this.selectedFormId = this._forms.length ? this._forms[0].id : null;
  }
  @Output() formSelected: EventEmitter<string> = new EventEmitter();

  selectedFormId: string = null;
  get forms(): FormOption[] {
    return this._forms;
  }

  onOptionSelected(form: FormOption) {
    if (form && form.id !== this.selectedFormId) {
      this.selectedFormId = form.id;
      this.formSelected.emit(this.selectedFormId);
    }
  }
}
