import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  SkipSelf,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from '@core/services/notification.service';
import { Observable, AsyncSubject, BehaviorSubject, of } from 'rxjs';
import { catchError, filter, switchMap, tap, map, first } from 'rxjs/operators';
import { FormDefinition, IEngineFormChangeEvent } from './engine-form.model';
import { ContextService } from '@core/services/context.service';
import {
  EntityMappingClient,
  EventType,
  FormType,
  GetMappingOperationsQuery,
  MappingOperation,
} from '@core/services/api-clients';
import { SavingExecutionEvent } from '@core/execution-context';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import {
  ExecutionEventType,
  FormContextType,
  FORM_CONTEXT,
  IControlContext,
  IExecutionContext,
  IFormContext,
  IFormContextInfo,
  ISubGridContext,
  ITabContext,
  ITabsGroupContext,
  NotificationGroup,
  NotificationType,
} from '../../../../engine-sdk';
import { WidgetType } from '@core/widgets/models/widget-type';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';
import { IWidgetEventArgs } from '@core/widgets/models/widget-event-args';
import { ENGINE_FORM_SERVICE, IEngineFormService } from '@core/engine-forms/models/iengine-form-service.model';
import { EngineFormNotificationService } from '@core/engine-forms/services/engine-form-notification.service';
import { FormValueMapper } from '@core/engine-forms/services/form-value-mapper.service';
import { UserInteractionService } from '@core/services/user-interaction.service';
import { UserInteractionEventType } from 'src/engine-sdk/contract/user-interaction';
import {
  ENGINE_DATA_CONTEXT_PROVIDER,
  IEngineDataContextProvider,
  IEngineFormDataContext,
} from 'src/engine-sdk/contract/engine-data-context';
import { EngineTranslationService } from '@core/engine-translations/services/translation.service';
import { LodashService } from '@core/services/lodash.service';
import { EngineFormDefinitionService } from '@core/engine-forms/services/form-definition.service';

@Component({
  selector: 'engine-form',
  templateUrl: './engine-form.component.html',
  styleUrls: ['./engine-form.component.scss'],
  providers: [
    {
      provide: WidgetDirective,
      useExisting: forwardRef(() => EngineFormComponent),
    },
    {
      provide: FORM_CONTEXT,
      useExisting: forwardRef(() => EngineFormComponent),
    },
    {
      provide: ENGINE_DATA_CONTEXT_PROVIDER,
      useExisting: forwardRef(() => EngineFormComponent),
    },
    EngineFormNotificationService,
    EngineFormDefinitionService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngineFormComponent
  extends WidgetDirective
  implements OnDestroy, IFormContext, IEngineDataContextProvider {
  private _definition: FormDefinition;
  private _tabsGroup: ITabsGroupContext | undefined;
  private _initialDataItem: any;
  private _dataItem: any;
  private _isInitiated$ = new BehaviorSubject<boolean>(false);
  private _isRefetched$ = new BehaviorSubject<boolean>(false);
  private _isDirty: boolean = false;
  private _hasChanges: boolean = false;
  private _notificationGroups: NotificationGroup[];

  @Input() set definition(definition: FormDefinition) {
    if (!definition) return;
    this._definition = definition;
    this._formDefinitionService.init(definition);
    this.widgetId = definition.id;
    this.uiScripts = definition.uiScripts;
    this.createFormGroup();
    if (!this._isInitiated$.value) {
      this.initiateForm();
    }
  }
  @Input() set dataItem(dataItem: any) {
    const isInitialDataItemSet = this._dataItem === undefined && dataItem != null;
    if (isInitialDataItemSet) {
      this._initialDataItem = dataItem;
    }
    const oldValue = this._dataItem ? LodashService.cloneDeep(this._dataItem) : undefined;
    this._dataItem = dataItem;
    if (!this._isInitiated$.value) {
      this.initiateForm();
    } else {
      const formValue = this._entityFormMapper.getFormValueFromDataItem(this._dataItem, this.definition);
      this.formGroup.patchValue(formValue);
      if (!isInitialDataItemSet) {
        this._hasChanges = Object.keys(this.getPatchDataItem()).some((key) => key != 'Id');
        this.dataItemChange.emit({ oldValue, newValue: this._dataItem });
      }
    }
  }
  @Input() selectedTabIndex: number;
  @Input() set notificationGroups(v: NotificationGroup[]) {
    this._notificationGroups = v;
    if (this._tabsGroup) {
      this.setNotificationsOnControls(this._notificationGroups);
    }
  }
  @Input() notificationGroupName: string;
  @Input() showDefinition: boolean = false;
  @Output() selectedTabChange = new EventEmitter<number>();
  @Output() dataItemChange = new EventEmitter<IEngineFormChangeEvent>();
  @Output() isDirtyChange = new EventEmitter<boolean>();
  @Output() initiated = new EventEmitter<EngineFormComponent>();

  formGroup: UntypedFormGroup;

  @HostBinding('id')
  get id(): string {
    return this._definition.id;
  }
  @HostBinding('attr.name')
  get name(): string {
    return this._definition.name;
  }
  get definition(): FormDefinition {
    return this._definition;
  }
  get dataItem(): any {
    return this._dataItem;
  }
  get notificationGroups(): NotificationGroup[] {
    return this._notificationGroups;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    @Optional()
    @SkipSelf()
    @Inject(ENGINE_DATA_CONTEXT_PROVIDER)
    private _engineDataContextProvider: IEngineDataContextProvider,
    private _userInteractionService: UserInteractionService,
    private _fb: UntypedFormBuilder,
    private _notificationService: NotificationService,
    private _contextService: ContextService,
    private _formNotificationService: EngineFormNotificationService,
    private _changeDetector: ChangeDetectorRef,
    @Inject(ENGINE_FORM_SERVICE) public readonly formService: IEngineFormService,
    private _entityFormMapper: FormValueMapper,
    private _entityMappingClient: EntityMappingClient,
    private _translationService: EngineTranslationService,
    private _formDefinitionService: EngineFormDefinitionService
  ) {
    super(parentWidget, scriptRunnerService);
    this._minWidgetsNumber = 1;
    this._formNotificationService.init(this);
  }

  ngOnDestroy(): void {
    this._userInteractionService.clearFeed(this.widgetId);
    this._contextService.unregisterForm(this);
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  registerTabsGroup(tabsGroup: ITabsGroupContext) {
    this._tabsGroup = tabsGroup;
    if (this._notificationGroups) {
      this.setNotificationsOnControls(this._notificationGroups);
    }
  }

  unregisterTabsGroup(tabsGroup: ITabsGroupContext) {
    this._tabsGroup = this._tabsGroup == tabsGroup ? undefined : this._tabsGroup;
  }

  isFormLoaded(): boolean {
    return this._isWidgetLoaded;
  }

  getPatchDataItem() {
    let patchValue = { ...this.dataItem };
    Object.keys(this._initialDataItem).forEach((key) => {
      if (key == 'Id') return;

      if (this._initialDataItem[key] == patchValue[key]) delete patchValue[key];
    });

    return patchValue;
  }

  getDataContext(): IEngineFormDataContext {
    const parentDataContext = this._engineDataContextProvider ? this._engineDataContextProvider.getDataContext() : {};
    return {
      ...parentDataContext,
      entityId: this.definition.recordInfo.entityId,
      entityName: this.definition.recordInfo.entityName,
      formId: this.definition.id,
      formName: this.definition.name,
      recordId: this.definition.recordInfo.recordId,
    };
  }

  //#region IFromContext
  getCurrentFormId(): string {
    return this.id;
  }

  getContextInfo(): IFormContextInfo {
    return this._definition.contextInfo;
  }

  save(): Observable<boolean> {
    const success$ = new AsyncSubject<boolean>();

    const save$ = of(this.validateForm()).pipe(
      filter((isValid) => isValid),
      switchMap(() => this.formService.save(this)),
      tap(() => {
        success$.next(true), success$.complete();
      }),
      catchError((e) => {
        success$.error(e);
        return of(e);
      }),
    );

    this._events$.next(
      new SavingExecutionEvent(ExecutionEventType.OnSaving, () =>
        this.triggerEvent(this.createEventArgs(EventType.OnSaving, () => save$.subscribe())),
      ),
    );
    return success$;
  }

  saveAndNew(): Observable<boolean> {
    const success$ = new AsyncSubject<boolean>();

    const saveAndNew$ = of(this.validateForm()).pipe(
      filter((isValid) => isValid),
      switchMap(() => this.formService.saveAndNew(this)),
      tap(() => {
        success$.next(true), success$.complete();
      }),
      catchError((e) => {
        success$.error(e);
        return of(e);
      }),
    );

    this._events$.next(
      new SavingExecutionEvent(ExecutionEventType.OnSaving, () =>
        this.triggerEvent(this.createEventArgs(EventType.OnSaving, () => saveAndNew$.subscribe())),
      ),
    );

    return success$;
  }

  saveAndClose(): Observable<boolean> {
    const success$ = new AsyncSubject<boolean>();

    const saveAndClose$ = of(this.validateForm()).pipe(
      filter((isValid) => isValid),
      switchMap(() => this.formService.saveAndClose(this)),
      tap(() => {
        success$.next(true), success$.complete();
      }),
      catchError((e) => {
        success$.error(e);
        return of(e);
      }),
    );

    this._events$.next(
      new SavingExecutionEvent(ExecutionEventType.OnSaving, () =>
        this.triggerEvent(this.createEventArgs(EventType.OnSaving, () => saveAndClose$.subscribe())),
      ),
    );

    return success$;
  }

  refetchData() {
    this._dataItem = undefined;
    return this.formService.refetch(this).pipe(
      tap(() => {
        this._isRefetched$.next(true);
      }),
    );
  }

  getTabsGroup(): ITabsGroupContext {
    return this._tabsGroup;
  }

  getTabByName(name: string): ITabContext {
    return this._tabsGroup.getTabByName(name);
  }

  getEntityInfo(): { entityId: string; entityName: string; recordId: string } {
    return this._definition.recordInfo;
  }

  getAllControls(): IControlContext[] {
    return this._tabsGroup.getTabs().flatMap((t) => t.getSections().flatMap((s) => s.getControls()));
  }

  getControlsByAttribute(attributeName: string): IControlContext[] {
    return this.getAllControls().filter((c) => c.getAttributes().primaryAttribute == attributeName);
  }

  getControlById(id: string): IControlContext {
    return this.getAllControls().find((c) => c.id == id);
  }

  getControlByName(name: string): IControlContext {
    return this.getAllControls().find((c) => c.name == name);
  }

  getSubgridById(id: string): ISubGridContext {
    return this.getAllControls().find((g) => g.id == id) as ISubGridContext;
  }

  getFieldValue(field: string): any {
    return this.dataItem[field];
  }

  setFieldValue(field: string, value: any, affectIsDirty: boolean = true): any {
    if (this.dataItem[field] == value) return;
    this.dataItem = {
      ...this.dataItem,
      [field]: value,
    };
    if (affectIsDirty) {
      this.updateIsDirty(true);
    }
  }

  hasChanges(): boolean {
    return this._hasChanges;
  }

  isDirty(): boolean {
    return this._isDirty || this.getAllControls().some((c) => c.isDirty());
  }

  markAsDirty(): void {
    this.updateIsDirty(true);
  }

  markAsPristine(): void {
    this.getAllControls()
      .filter((c) => c.type != 'subgrid')
      .forEach((c) => {
        c.setDirty(false);
        c.setTouched(false);
      });
    this._isDirty = false;
    this._userInteractionService.feed({
      providerId: this.widgetId,
      context: {
        ...this.getDataContext(),
        eventType: UserInteractionEventType.formStateChanged,
      },
      value: false,
    });
    this.isDirtyChange.emit(false);
  }

  hasErrors(): boolean {
    return this.getAllControls().some((c) => c.hasErrors());
  }
  //#endregion

  //#region WidgetDirective
  override onLoaded(): void {
    super.onLoaded();
    this._userInteractionService.feed({
      providerId: this.widgetId,
      context: {
        ...this.getDataContext(),
        eventType: UserInteractionEventType.formLoaded,
      },
      value: this.isFormLoaded(),
    });
    this._userInteractionService.feed({
      providerId: this.widgetId,
      context: {
        ...this.getDataContext(),
        eventType: UserInteractionEventType.formStateChanged,
      },
      value: this.isDirty(),
    });

    this.focusFirstVisibleControl();
  }

  override triggerEvent(eventArgs: IWidgetEventArgs): void {
    const widget = eventArgs.widget;
    const isFormControlWidget = widget.getWidgetType() == WidgetType.FormControl;
    const isFormControlChangedEvent = isFormControlWidget && eventArgs.eventType == EventType.OnChanged;
    if (this.isEventTriggeredBeforeLoad(eventArgs) && isFormControlChangedEvent) return;

    super.triggerEvent(eventArgs);

    if (isFormControlChangedEvent) {
      this.onFormControlWidgetChanged(widget);
    }
  }

  getWidgetType(): WidgetType {
    return WidgetType.Form;
  }

  getExecutionContext(): Observable<IExecutionContext> {
    return this._contextService.createFormExecutionContext(this, this._events$.asObservable(), {
      dialogId: this.definition.contextInfo.dialogId,
      formId: this.getContextInfo().type == FormContextType.SubForm ? this.id : undefined,
    });
  }

  protected override isWidgetInitiated(): Observable<boolean> {
    return this._isInitiated$.asObservable();
  }

  protected override isWidgetRefreshed(): Observable<boolean> {
    return this._isRefetched$.asObservable().pipe(filter((x) => !!x));
  }
  //#endregion

  private initiateForm() {
    const isReadyToInitiate = this.definition && this.dataItem;
    if (this._isInitiated$.value || !isReadyToInitiate) return;
    const formValue = this._entityFormMapper.getFormValueFromDataItem(this.dataItem, this.definition);
    this.formGroup.patchValue(formValue, { emitEvent: false });
    this._changeDetector.markForCheck();
    this._contextService.registerForm(this);
    this._isInitiated$.next(true);
    this.initiated.emit(this);
  }

  private createFormGroup() {
    const formGroup = {};
    const controls = this.definition.tabs.flatMap((t) => t.controlGroups).flatMap((g) => g.controls);
    controls.forEach((c) => {
      const control: any[] = [
        {
          value: undefined,
          disabled: c.isReadOnly,
        },
      ];
      formGroup[c.id] = control;
    });
    this.formGroup = this._fb.group(formGroup);
  }

  private setNotificationsOnControls(notificationGroups: NotificationGroup[]) {
    let groups: { [name: string]: NotificationGroup } = {};
    notificationGroups.forEach((group) => {
      groups = { ...groups, [group.name]: group };
    });

    this.getAllControls().forEach((c) => {
      const primaryAttribute = c.getAttributes().primaryAttribute;
      if (!c.isReadOnly && groups[primaryAttribute]) {
        const errorMessages = [
          ...new Set(
            groups[primaryAttribute].notifications
              .filter((n) => n.type === NotificationType.Error)
              .map((n) => n.message),
          ),
        ];
        c.setErrors(errorMessages);
      }
    });
  }

  private validateForm(): boolean {
    this.getAllControls()
      .filter((c) => c.type != 'subgrid')
      .forEach((c) => c.setTouched(true));
    if (this.hasErrors()) {
      const message = this._translationService.translateInstantly('Form.FormContainsErrors');
      this._notificationService.error(message);
    }
    return !this.hasErrors();
  }

  private onFormControlWidgetChanged(formControlWidget: WidgetDirective) {
    const formControl = this.getControlById(formControlWidget.widgetId);
    if (formControl) {
      this.onFormControlValueChanged(formControl);
    }

    if (formControl.type == 'lookup' && formControl.value != undefined) {
      this.onFormLookupWidgetChanged(formControl);
    }
  }

  private _mappingCalculationInProgress = false;
  private onFormLookupWidgetChanged(formControl: IControlContext): void {
    const dataContext = this.getDataContext();
    if (this._mappingCalculationInProgress || !dataContext.entityName) return;

    this._mappingCalculationInProgress = true;
    const formType = this.dataItem.Id == undefined ? FormType.Create : FormType.Update;
    const getMappingOperationsQuery = new GetMappingOperationsQuery();
    getMappingOperationsQuery.entityName = dataContext.entityName;
    getMappingOperationsQuery.formType = formType;
    getMappingOperationsQuery.lookupAttributeName = formControl.getAttributes().primaryAttribute;
    getMappingOperationsQuery.recordValues = this.dataItem;

    getMappingOperationsQuery.recordValues[getMappingOperationsQuery.lookupAttributeName] = formControl.value;

    this._entityMappingClient
      .getMappingOperations(getMappingOperationsQuery)
      .pipe(
        first(),
        map((results) => {
          results.mappingOrder.forEach((mappingResult) => {
            const operation = mappingResult.usedMappingOperation;
            mappingResult.attributes.forEach((changedAttribute) => {
              const newValue = results.recordValues[changedAttribute];
              const currentValue = this.dataItem[changedAttribute];
              const currentIsEmpty = currentValue != true;
              if (newValue != currentValue || (!currentIsEmpty && operation == MappingOperation.AlwaysSet)) {
                this.setFieldValue(changedAttribute, newValue);
              }
            });
          });
        }),
        tap(() => (this._mappingCalculationInProgress = false)),
      )
      .subscribe();
  }

  private onFormControlValueChanged(formControl: IControlContext) {
    const controlAttributes = formControl.getAttributes();
    const isComplexControl = controlAttributes.primaryAttribute && controlAttributes.secondaryAttribute;
    if (!isComplexControl) {
      // There are a few controls which are not associated with any attributes like e.g. inline-relation-control
      const isAssociatedWithAttribute = controlAttributes.primaryAttribute != null;
      if (isAssociatedWithAttribute) {
        const controlDefinition = this._formDefinitionService.getControlDefinition(formControl.id);
        const newDataItem = LodashService.cloneDeep(this.dataItem);
        this._entityFormMapper.updateDataItemWithControlValue(newDataItem, controlDefinition, formControl.value);
        if (LodashService.areEqual(newDataItem, this.dataItem)) return;
        this.dataItem = { ...this.dataItem, ...newDataItem };
        this.updateIsDirty(true);
      }
    }
  }

  private updateIsDirty(isDirty: boolean = true): void {
    this._isDirty = isDirty;
    this._userInteractionService.feed({
      providerId: this.widgetId,
      context: {
        ...this.getDataContext(),
        eventType: UserInteractionEventType.formStateChanged,
      },
      value: isDirty,
    });
    this.isDirtyChange.emit(isDirty);
  }

  private focusFirstVisibleControl() {
    const control = this.getAllControls().find(x => x.type != 'querybuilder'
      && x.type != 'subgrid'
      && x.type != 'chart'
      && x.isVisible
    );

    if (control) {
      control.tryFocus();
    }
  }
}
