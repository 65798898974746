import { InjectionToken } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { IExecutionContext } from '../execution';
import { IContextMenuContext } from '../menu';
import { IViewContext } from '../view/iview-context';
import { ChartData, IChartConfiguration } from '@shared/ui-components/components/chart/chart.model';
import { IEngineFormControlDataContext, IEngineFormDataContext } from '../engine-data-context';

export const FORM_CONTEXT = new InjectionToken<IFormContext>('FormContext');

export interface IFormContext {
  readonly id: string;
  readonly name: string;

  getCurrentFormId(): string;
  getDataContext(): IEngineFormDataContext;
  isDirty(): boolean;
  hasChanges(): boolean;
  markAsDirty(): void;
  markAsPristine(): void;
  hasErrors(): boolean;

  save(): Observable<boolean>;
  saveAndNew(): Observable<boolean>;
  saveAndClose(): Observable<boolean>;
  refetchData(): Observable<boolean>;
  getExecutionContext(): Observable<IExecutionContext>; // TODO-MP: it's temporary

  getTabsGroup(): ITabsGroupContext;
  getTabByName(name: string): ITabContext;

  getAllControls(): IControlContext[];
  getControlById(id: string): IControlContext;
  getControlByName(name: string): IControlContext;
  getControlsByAttribute(attributeName: string): IControlContext[];
  getSubgridById(id: string): ISubGridContext;
  getFieldValue(field: string): any;
  setFieldValue(field: string, value: any);

  // DEPRECATED
  getEntityInfo(): { entityId: string; entityName: string; recordId: string }; // use getDataContext instead
  getContextInfo(): IFormContextInfo; // use getDataContext instead
}

export interface IFormBaseElement {
  readonly id: string;
  readonly name: string;
}

export interface ITabsGroupContext {
  readonly selectedTabIndex: number;
  getTabs(): ITabContext[];
  getTabByName(name: string): ITabContext;
  setTabVisibility(name: string, value: boolean);
  getControls(): IControlContext[];
  selectedTabChange: Observable<number>;
}

export interface ITabContext extends IFormBaseElement {
  isVisible: boolean;
  getSections(): IFormSectionContext[];
  getSectionByName(name: string): IFormSectionContext;
  getControls(): IControlContext[];
}

export interface IFormSectionContext extends IFormBaseElement {
  isVisible: boolean;
  getControls(): IControlContext[];
}

export interface IControlContext extends IFormBaseElement {
  label: string;
  type: string;
  value: any;
  isVisible: boolean;
  isReadOnly: boolean;
  isRequired: boolean;
  getAttributes(): { primaryAttribute: string; secondaryAttribute?: string };
  isDirty(): boolean;
  setDirty(value: boolean): void;
  isTouched(): boolean;
  setTouched(value: boolean): void;
  setErrors(messages: string[]): void;
  hasErrors(): boolean;
  hasError(errorId: string): boolean;
  getDataContext(): IEngineFormControlDataContext;
  tryFocus();
}

export interface ILookupControlContext extends IControlContext {
  addFilter(filter: CompositeFilterDescriptor): void;
  setFilter(filter: CompositeFilterDescriptor): void;
  setCustomFilter(filter: string): void;
}

export interface ISubGridContext extends IControlContext {
  getViewContext(): IViewContext;
  getSubgridToolbar(): IContextMenuContext;
  getRelatedEntityForeignKey(): string;
}

export interface IMultiselectOptionSetControlContext extends IControlContext {
  filterOptionsByName(values: string[]): void;
  resetOptionsFilter(): void;
}

export interface IOptionSetControlContext extends IControlContext {
  filterOptionsByName(values: string[]): void;
  resetOptionsFilter(): void;
}

export interface IChartControlContext extends IControlContext {
  setConfiguration(configuration: IChartConfiguration): void;
  updateData(data: ChartData): void;
}

export interface ISimpleRecord {
  id: string;
  name: string;
}

export interface IInlineRelationControlContext extends IControlContext {
  add(record: ISimpleRecord);
  remove(recordId: string);

  options: ISimpleRecord[];

  filterChange: Observable<string>;
  optionSelect: Observable<ISimpleRecord>;
}

export interface IFormContextInfo {
  type: FormContextType;
  dialogId?: string;
}

export enum FormContextType {
  Main,
  Dialog,
  SubForm,
}
