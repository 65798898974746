import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  Inject,
  OnInit,
  OnDestroy,
  forwardRef,
  SkipSelf,
  Optional,
  ChangeDetectorRef,
  HostBinding,
} from '@angular/core';
import { SubGridControlDto } from '@core/services/api-clients';
import {
  FORM_CONTEXT,
  IContextMenuContext,
  IFormContext,
  ISubGridContext,
  IViewContext,
} from '../../../../../engine-sdk';
import { GridData } from '@shared/grid/models/grid-data.model';
import { SubgridViewComponent } from '@core/engine-views/components/subgrid-view/subgrid-view.component';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ODataState } from '@shared/odata/models/odata-state.model';
import { FormSectionComponent } from '@core/engine-forms/components/engine-form/form-grid/form-section/form-section.component';
import {
  ENGINE_DATA_CONTEXT_PROVIDER,
  IEngineDataContextProvider,
  IEngineFormControlDataContext,
  IEngineFormDataContext,
} from '../../../../../engine-sdk/contract/engine-data-context';

@Component({
  selector: 'engine-subgrid-control',
  templateUrl: './engine-subgrid-control.component.html',
  styleUrls: ['./engine-subgrid-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ENGINE_DATA_CONTEXT_PROVIDER,
      useExisting: forwardRef(() => EngineSubgridControlComponent),
    },
  ],
})
export class EngineSubgridControlComponent implements OnInit, OnDestroy, ISubGridContext, IEngineDataContextProvider {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _subgridControl: SubGridControlDto;
  private _isVisible: boolean;

  get subgridControl() {
    return this._subgridControl;
  }
  @Input() set subgridControl(value: SubGridControlDto) {
    if (this._subgridControl != value) {
      this._subgridControl = value;

      if (this._subgridControl) {
        this.parentRecordId = this._formContext.getEntityInfo().recordId;

        this.id = this._subgridControl.id;
        this.name = this._subgridControl.name;
        this.label = this._subgridControl.label;
        this.type = this._subgridControl.type;
        this.isVisible = this._subgridControl.isVisible;
        this.isRequired = this._subgridControl.isRequired;
        this.isReadOnly = this._subgridControl.isReadOnly;

        const entityId = this._formContext.getEntityInfo().entityId;
        this.isDataFetchingEnabled = this.isSubgridOnCurrentTab() && (!entityId || this.parentRecordId != null);

        if (this._subgridControl.primaryAttribute && this.parentRecordId) {
          this.systemQuery = this.buildSystemQueryForSubgrid();
        }
      }
    }
  }

  @ViewChild('subgridView') subgridView: SubgridViewComponent;

  parentRecordId: string;
  systemQuery: ODataState;
  isDataFetchingEnabled: boolean;

  constructor(
    @Optional()
    @SkipSelf()
    @Inject(ENGINE_DATA_CONTEXT_PROVIDER)
    private _engineDataContextProvider: IEngineDataContextProvider,
    protected _formSection: FormSectionComponent,
    @Inject(FORM_CONTEXT) protected _formContext: IFormContext,
    private _changeDetector: ChangeDetectorRef,
  ) {
    this._formSection.registerControl(this);
  }

  ngOnInit() {
    this._formContext
      .getTabsGroup()
      .selectedTabChange.pipe(
        takeUntil(this._destroy$),
        tap((tabIndex) => {
          const entityId = this._formContext.getEntityInfo().entityId;
          this.isDataFetchingEnabled = this.isSubgridOnTab(tabIndex) && (!entityId || this.parentRecordId != null);
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
    this._formSection.unregisterControl(this);
  }

  // ISubGridContext

  id: string;
  name: string;
  label: string;
  type: string;
  isReadOnly: boolean;
  isRequired: boolean;

  get isVisible(): boolean {
    return this._isVisible;
  }
  @Input() set isVisible(value) {
    if (this._isVisible != value) {
      this._isVisible = value;
      this._changeDetector.markForCheck();
    }
  }

  get value(): GridData {
    return this.subgridView?.engineViewComponent?.dataProvider.value;
  }

  set value(_) {
    throw new Error('Not supported.');
  }

  @HostBinding('id')
  get getEngineControlId(): string {
    return `EngineControl_${this.id}`;
  }
  @HostBinding('attr.name')
  get getEngineControlName(): string {
    return `EngineControl_${this.name}`;
  }

  getAttributes(): { primaryAttribute: string; secondaryAttribute?: string } {
    return { primaryAttribute: null, secondaryAttribute: null };
  }

  setDirty(value: boolean): void {
    throw new Error('Not supported.');
  }

  setTouched(value: boolean): void {
    throw new Error('Not supported.');
  }

  isDirty() {
    return false;
  }
  isTouched() {
    return false;
  }

  setErrors(messages: string[]): void {
    throw new Error('Not supported.');
  }

  hasErrors(): boolean {
    return false;
  }

  hasError(errorId: string): boolean {
    return false;
  }

  getViewContext(): IViewContext {
    return this.subgridView?.engineViewComponent;
  }

  getRelatedEntityForeignKey(): string {
    return this.subgridControl.primaryAttribute;
  }

  getSubgridToolbar(): IContextMenuContext {
    return this.subgridView?.contextMenuComponent;
  }

  getDataContext(): IEngineFormControlDataContext {
    const parentDataContext = this._engineDataContextProvider ? this._engineDataContextProvider.getDataContext() : {};
    return { ...(parentDataContext as IEngineFormDataContext), controlId: this.id, controlName: this.name };
  }

  tryFocus() {
  }

  // #endregion

  private buildSystemQueryForSubgrid(): ODataState {
    return {
      filter: {
        logic: 'and',
        filters: [
          {
            field: this._subgridControl.primaryAttribute,
            operator: 'eq',
            value: this.parentRecordId,
          },
        ],
      },
    };
  }

  private isSubgridOnCurrentTab() {
    const tabsGroup = this._formContext.getTabsGroup();
    return this.isSubgridOnTab(tabsGroup.selectedTabIndex);
  }

  private isSubgridOnTab(tabIndex: number) {
    const tabsGroup = this._formContext.getTabsGroup();
    const tab = tabsGroup.getTabs()[tabIndex];
    return tab.getControls().find((x) => x.id == this.id) != null;
  }
}
