import { Injectable } from '@angular/core';
import { ODataConfig } from './odata-config';
import { Observable } from 'rxjs';
import { ODataQuery, ODataService, ODataServiceFactory } from '@core/odata';

@Injectable()
export class BaseODataService<TEntity> {
  protected readonly MAX_COUNT = 2147483647;

  protected _service: ODataService<TEntity>;

  public constructor(private _serviceFactory: ODataServiceFactory, private _typeName: string) {
    this._service = this._serviceFactory.CreateService<TEntity>(this._typeName, new ODataConfig());
  }

  public create(entity: TEntity) {
    return this._service.Post(entity).Exec();
  }

  public get(id: string): Observable<TEntity> {
    return this._service.Get(id).Exec();
  }

  public getAll(): Observable<TEntity[]> {
    return this._service.Query().MaxPerPage(this.MAX_COUNT).Exec();
  }

  public update(id: string, entity: TEntity) {
    return this._service.Patch(entity, id).Exec();
  }

  public delete(id: string) {
    return this._service.Delete(id).Exec();
  }

  public query(): ODataQuery<TEntity> {
    return this._service.Query();
  }
}
