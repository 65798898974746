import { Pipe, PipeTransform } from '@angular/core';
import { GridColumn } from '../models/grid-definition.model';

export interface Hyperlink {
  url: string,
  placeholder: string
}

@Pipe({ name: 'hyperlink' })
export class HyperlinkPipe implements PipeTransform {
  transform(dataItem: any, column: GridColumn): Hyperlink {
    if (!dataItem) return null;

    const primaryValue = this.getNestedValue(dataItem, column.primaryField);
    const secondaryValue = this.getNestedValue(dataItem, column.secondaryField);

    return {
      url: secondaryValue ?? primaryValue,
      placeholder: primaryValue ?? column.linkPlaceholder
    }
  }

  private getNestedValue(dataItem: any, field: string): any {
    if (!field) return null;

    let fields = field.split('.');
    let item = dataItem;
    fields.forEach((element) => {
      if (!item) return null;
      item = item[element];
    });

    return item;
  }
}
