import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ODataState } from '../../../shared/odata/models/odata-state.model';
import { ODataStateService } from '../../../shared/odata/services/odata-state.service';
import { IAutocompleteOption } from '../../../shared/reactive-controls/components/autocomplete/autocomplete-control.model';
import { IGNORE_BUSY_INDICATOR } from '../../layout/components/busy-indicator/busy-indicator.interceptor';
import { EntityDto } from '../../services/api-clients';

@Injectable()
export class EngineInlineRelationService {
  private readonly BASE_URL = environment.urls.ODataUrl;

  private _sourceEntity: BehaviorSubject<EntityDto> = new BehaviorSubject(undefined);
  private _sourceAttributeName: BehaviorSubject<string> = new BehaviorSubject(undefined);
  private _targetRecordId: BehaviorSubject<string> = new BehaviorSubject(undefined);

  private _relatedRecords = new BehaviorSubject<IAutocompleteOption[]>([]);
  relatedRecords$ = this._relatedRecords.asObservable();

  private _options = new BehaviorSubject<IAutocompleteOption[]>([]);
  options$ = this._options.asObservable();

  constructor(private _http: HttpClient) {}

  init(sourceEntity: EntityDto, sourceAttributeName: string, targetRecordId: string) {
    if (!sourceEntity || !sourceAttributeName || !targetRecordId) return;

    this._sourceEntity.next(sourceEntity);
    this._sourceAttributeName.next(sourceAttributeName);
    this._targetRecordId.next(targetRecordId);

    this.fetchRelatedRecords();
  }

  remove(recordId: string) {
    let url = `${this.BASE_URL}/${this._sourceEntity.value.name}(${recordId})`;

    const context = new HttpContext();
    context.set(IGNORE_BUSY_INDICATOR, true);
    return this._http.delete(`${url}`);
  }

  private fetchRelatedRecords() {
    if (!this._sourceEntity.value || !this._sourceAttributeName.value || !this._targetRecordId.value) {
      throw new Error(
        `Invalid inline relation control definition (sourceEntity: ${this._sourceEntity.value}, sourceAttributeName: ${this._sourceAttributeName.value}, targetRecordId: ${this._targetRecordId.value})`,
      );
    }

    const query = this.getQuery();
    this.query(this._sourceEntity.value.name, 'Name', query)
      .pipe(
        first(),
        tap((options) => this._relatedRecords.next(options)),
      )
      .subscribe();
  }

  private query(entityName: string, showField: string, state: ODataState): Observable<IAutocompleteOption[]> {
    let url = `${this.BASE_URL}/${entityName}?${ODataStateService.toODataString(
      state,
    )}& $select=Id, ${showField}& $orderby=${showField}`;

    url = url.replace(/'([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})'/gi, '$1');

    const context = new HttpContext();
    context.set(IGNORE_BUSY_INDICATOR, true);
    return this._http.get(`${url}`, { context }).pipe(
      map((res) => res['value']),
      map((data) =>
        data.map((item) => {
          return { value: item.Id, label: item[showField] };
        }),
      ),
    );
  }

  private getQuery(): ODataState {
    return {
      take: 50,
      skip: 0,
      filter: {
        logic: 'and',
        filters: [
          {
            field: `${this._sourceAttributeName.value}Id`,
            operator: 'eq',
            value: this._targetRecordId.value,
          },
        ],
      },
    };
  }
}
