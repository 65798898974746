import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GenericODataServiceProvider } from '@core/services/generic-odata-service-provider';
import { NavigationService } from '../navigation/services/navigation.service';
import { UserContext } from '../user/models/user-context';
import { MenuContext } from '../context-menu/models/menu-context';
import { CancellationToken, ContextService, ICancellationTokenProvider } from '@core/services/context.service';
import { ODataService } from '../services/odata.service';
import { map } from 'rxjs/operators';
import { ToolsService } from '../services/tools-service';
import { PermissionContext } from '../user/models/permission-context';
import { HttpClient } from '@angular/common/http';
import {
  IDialogContext,
  IErrorContext,
  IExecutionContext,
  IExecutionEvent,
  IFormContext,
  IMenuContext,
  INavigationContext,
  INotificationContext,
  IODataService,
  IOverviewContext,
  IPermissionContext,
  ISystemContext,
  IToolsService,
  IUserContext,
  IViewContext,
} from 'src/engine-sdk';
import { Injector } from '@angular/core';
import { IKanbanContext } from 'src/engine-sdk/contract/kanban';
import { SystemService } from '@core/services/system.service';
import { NotificationContext } from '@core/notification/models/notification-context.model';
import { IErrorProvider, ERROR_PROVIDER } from '@core/errors/services/error-event.service';
import { ErrorHandlerProvider } from '@core/errors/services/error-handler-provider.service';
import {
  IEngineNotificationService,
  ENGINE_NOTIFICATION_SERVICE,
} from '@core/notification/services/engine-nofitication.service';
import { ErrorContext } from '../errors/models/error-context';
import { IUserInteractionService, USER_INTERACTION_SERVICE } from 'src/engine-sdk/contract/user-interaction';
import { NavigationSelectors } from '@core/navigation/store';
import { RouterService } from '@core/router/services/router.service';
import { LayoutService } from '@core/layout/services/layout.service';
import { LodashService } from '@core/services/lodash.service';

export interface IButtonContext {
  getVisibility(): boolean;
  setVisibility(value: boolean): void;
  setLabel(value: string): void;
  setFontIcon(fontIconName: string): void;
  setResourceIcon(iconRelativePath: string): void;
  setDisabled(value: boolean): void;
}

export class ExecutionContext implements IExecutionContext, ICancellationTokenProvider {
  private _data: Observable<any>;
  private _args: Observable<any>;

  protected _contextService: ContextService;
  protected _odataServiceProvider: GenericODataServiceProvider;
  protected _engineNotificationService: IEngineNotificationService;
  protected _toolsService: ToolsService;
  protected _errorHandlerProvider: ErrorHandlerProvider;
  protected _errorProvider: IErrorProvider;
  protected _httpClient: HttpClient;
  protected _systemService: SystemService;
  protected _store: Store;
  protected _userInteractionService: IUserInteractionService;
  protected _routerService: RouterService;
  protected _layoutService: LayoutService;

  constructor(
    protected _injector: Injector,
    protected _cancellationToken: CancellationToken,
    protected _event?: IExecutionEvent,
    protected _dialogId?: string,
  ) {
    this.initiateRequiredServices();
    this._data = this._store.select(NavigationSelectors.getDataQueryParam);
    this._args = this._store
      .select(NavigationSelectors.getArgsQueryParam)
      .pipe(map((args) => LodashService.cloneDeep(args)));
  }

  private initiateRequiredServices() {
    this._contextService = this._injector.get(ContextService);
    this._odataServiceProvider = this._injector.get(GenericODataServiceProvider);
    this._engineNotificationService = this._injector.get(ENGINE_NOTIFICATION_SERVICE);
    this._toolsService = this._injector.get(ToolsService);
    this._errorHandlerProvider = this._injector.get(ErrorHandlerProvider);
    this._errorProvider = this._injector.get(ERROR_PROVIDER);
    this._httpClient = this._injector.get(HttpClient);
    this._systemService = this._injector.get(SystemService);
    this._store = this._injector.get(Store);
    this._userInteractionService = this._injector.get(USER_INTERACTION_SERVICE);
    this._routerService = this._injector.get(RouterService);
    this._layoutService = this._injector.get(LayoutService);
  }

  getErrorContext(): IErrorContext {
    return new ErrorContext(this._errorHandlerProvider, this._errorProvider);
  }

  getNotificationContext(): INotificationContext {
    const formCtx = this.getFormContext();
    return formCtx
      ? new NotificationContext(this._engineNotificationService, formCtx.getEntityInfo().entityName)
      : new NotificationContext(this._engineNotificationService);
  }

  getExecutionEvent(): IExecutionEvent {
    return this._event;
  }
  getODataService(entityName: string): IODataService {
    return new ODataService(this._odataServiceProvider.create(entityName));
  }
  getNavigationContext(): INavigationContext {
    return new NavigationService(this._store, this._routerService);
  }
  getUserContext(): IUserContext {
    return new UserContext(this._store);
  }
  getOverviewContext(): IOverviewContext {
    return undefined;
  }
  getFormContext(): IFormContext {
    return this._dialogId
      ? this._contextService.getDialogFormContext(this._dialogId)
      : this._contextService.getMainFormContext(); // TODO-MP: tutaj powinna być użyte getFormContext(this.dataContext.formId)
  }
  getSelectionContext() {}
  getViewContext(): IViewContext {
    return this._contextService.createViewContext();
  }
  getMenuContext(): IMenuContext {
    return new MenuContext(this._layoutService, this._contextService);
  }
  getToolsService(): IToolsService {
    return this._toolsService;
  }
  getUserInteractionService(): IUserInteractionService {
    return this._userInteractionService;
  }

  getCancellationToken(): CancellationToken {
    return this._cancellationToken;
  }

  getContextData(): Observable<any> {
    return this._data;
  }
  getContextArgs(): Observable<any> {
    return this._args;
  }
  getDialogContext(): IDialogContext {
    return this._contextService.getDialogContext(this._dialogId);
  }

  getPermissionContext(): IPermissionContext {
    return new PermissionContext(this._store);
  }

  getSystemContext(): ISystemContext {
    return this._systemService;
  }

  getHttpClient(): HttpClient {
    return this._httpClient;
  }

  getKanbanContext(): IKanbanContext {
    return this._contextService.getKanbanContext();
  }
}
