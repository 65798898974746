import { createSelector, createFeatureSelector } from '@ngrx/store';
import { UserFeatureState } from '.';
import { FEATURE_NAME } from './state';

export const userFeatureSelector = createFeatureSelector<UserFeatureState>(FEATURE_NAME);
export const userSelector = createSelector(userFeatureSelector, (state) => state.user);

export const isNotAuthorized = createSelector(userSelector, (state) => state.isNotAuthorized);
export const isUserLoggedIn = createSelector(userSelector, (state) => !!state.currentUser);
export const getCurrentUser = createSelector(userSelector, (state) => state.currentUser);
export const getCurrentUserId = createSelector(userSelector, (state) =>
  state.currentUser ? state.currentUser.userId : null,
);
export const getCurrentUserPermissionKey = createSelector(userSelector, (state) =>
  state.currentUser ? state.currentUser.permissionKeyId : undefined,
);
export const getCurrentUserRoles = createSelector(userSelector, (state) =>
  state.currentUser ? state.currentUser.roles : [],
);
export const getCurrentUserPhoto = createSelector(userSelector, (state) =>
  state.currentUser ? state.currentUser.photoRelativePath : null,
);
export const getUserLanguage = createSelector(userSelector, (state) => state.userLanguage);
export const getUserCulture = createSelector(userSelector, (state) =>
  state.currentUser ? state.currentUser.culture : null,
);
export const getUILanguages = createSelector(userSelector, (state) => state.uiLanguages);

export const checkCurrentUserPermissionKey = (permissionKeyId: string) =>
  createSelector(userSelector, (state) => state.currentUserPermissionKeyMap[permissionKeyId]);
