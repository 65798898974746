import { Injector } from '@angular/core';
import { Location } from '@angular/common';
import {
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
} from '@azure/msal-angular';
import { environment } from '@env';
import { ADAuthInterceptor } from '../interceptors/ad-auth.interceptor';
import { AuthenticationType } from '../models/authentication-type.enum';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

const authInterceptorFactory = (injector: Injector) => {
  switch (environment.authenticationType) {
    case AuthenticationType.Anonymous:
      return null;
    case AuthenticationType.ActiveDirectory:
      return new ADAuthInterceptor();
    case AuthenticationType.AzureActiveDirectory:
    case AuthenticationType.AzureB2C:
      return new MsalInterceptor(
        injector.get<MsalInterceptorConfiguration>(MSAL_INTERCEPTOR_CONFIG),
        injector.get(MsalService),
        injector.get(Location),
        window.document,
      );
  }
};

export const authInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useFactory: authInterceptorFactory,
  deps: [Injector],
  multi: true,
};
