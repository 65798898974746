import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';

export class BreadcrumbItem {
  label?: string;
  type?: 'record' | 'view' | 'form' | 'unknown';
  popCount?: number;
}

@Component({
  selector: 'app-breadcrumb-item',
  templateUrl: './breadcrumb-item.component.html',
  styleUrls: ['./breadcrumb-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbItemComponent {
  @Input() level: number = 0;
  @Input() item: BreadcrumbItem;
  @Output() onClick: EventEmitter<BreadcrumbItem> = new EventEmitter<BreadcrumbItem>();

  hovered: boolean = false;
  filteredOptions: Subject<{ label: string; value: string }[]> = new Subject<{ label: string; value: string }[]>();

  constructor() {}

  getClasses(): { [key: string]: boolean } {
    const classes = {};
    classes[`level-${this.level}`] = true;
    classes['hovered'] = this.hovered;
    return classes;
  }
}
