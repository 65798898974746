import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IOperator, IRule } from '../query-builder.model'
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, tap } from 'rxjs/operators';
import { IEntityAttribute } from '@core/metadata/imetadata-provider.service'
import { LodashService } from '@core/services/lodash.service';


@Component({
  selector: 'app-query-builder-rule',
  templateUrl: './query-builder-rule.component.html',
  styleUrls: ['./query-builder-rule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryBuilderRuleComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  ruleForm = new FormGroup({
    field: new FormControl(),
    operator: new FormControl(),
    entity: new FormControl(),
    value: new FormControl(),
  });

  operators: IOperator[] = [
    { text: '=', value: 'eq' },
    { text: '!=', value: 'ne' }
  ];

  @Input() fields: IEntityAttribute[] = [];

  @Input() set rule(rule: IRule) {
    this._rule = LodashService.cloneDeep(rule);
    this.ruleForm.setValue(this._rule);
  }
  get() {
    return this._rule;
  }
  private _rule: IRule;

  @Output() ruleChange = new EventEmitter<IRule>();

  constructor() { }

  ngOnInit() {
    this.ruleForm.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(300),
        tap((value) => this.ruleChange.emit(<IRule>value)),
      )
      .subscribe();

    this.ruleForm.controls['field'].valueChanges.pipe(
      takeUntil(this._destroy$),
      tap(() => {
        this.ruleForm.controls['operator'].setValue(null);
        this.ruleForm.controls['value'].setValue(null);
      })
    )
    .subscribe()
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
