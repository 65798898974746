import { Component, Input, ChangeDetectionStrategy, forwardRef, Injector, Inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseFormControlDirective } from '../../directives/base-form-control.directive';
import { CULTURE_SERVICE, ICultureService } from '../../models/iculture-service.model';

@Component({
  selector: 'app-text-control',
  templateUrl: './text-control.component.html',
  styleUrls: ['./text-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: TextControlComponent,
    },
  ],
})
export class TextControlComponent extends BaseFormControlDirective {
  private _minLength: number = null;
  private _maxLength: number = null;

  @Input() set minLength(v: number | undefined | null) {
    if (Number.isInteger(v)) {
      this._minLength = v;
      this.addValidator('minLength', Validators.minLength(v));
    } else {
      this._minLength = null;
      this.removeValidator('minLength');
    }
  }

  @Input() set maxLength(v: number | undefined | null) {
    if (Number.isInteger(v)) {
      this._maxLength = v;
      this.addValidator('maxLength', Validators.maxLength(v));
    } else {
      this._maxLength = null;
      this.removeValidator('maxLength');
    }
  }

  get minLength() {
    return this._minLength;
  }

  get maxLength() {
    return this._maxLength;
  }

  constructor(@Inject(CULTURE_SERVICE) cultureService: ICultureService, injector: Injector) {
    super(cultureService, injector);
  }
}
