import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from '@core/core.module';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { MainKanbanModule } from './main-kanban/main-kanban.module';
import { MainViewModule } from './main-view/main-view.module';
import { ErrorModule } from '@core/errors/error.module';
import { MenuModule } from '@core/menu/menu.module';
import { NavigationModule } from '@core/navigation/navigation.module';
import { UserModule } from '@core/user/user.module';
import { LayoutModule } from '@core/layout/layout.module';
import { TestFormModule } from './test-form/test-form.module';
import { MainFormModule } from './main-form/main-form.module';

const featureModules = [MainKanbanModule, MainViewModule, MainFormModule, TestFormModule];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonModule,
    CoreModule,
    NavigationModule,
    ErrorModule,
    LayoutModule,
    MenuModule,
    UserModule,
    featureModules,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
