import { createSelector, createFeatureSelector } from '@ngrx/store';
import { NavigationsFeatureState } from '.';
import { FEATURE_NAME } from './state';
import { UrlState } from '@core/router/store/actions';

export const navigationFeatureSelector = createFeatureSelector<NavigationsFeatureState>(FEATURE_NAME);
export const navigationSelector = createSelector(
  navigationFeatureSelector,
  (state: NavigationsFeatureState) => state.navigations,
);

export const getNavigationTargets = createSelector(navigationSelector, (state) => state.navigationTargets);
export const getNavigationStack = createSelector(navigationSelector, (state) => state.navigationStack);
export const getEntityForms = createSelector(navigationSelector, (state) => state.entityForms);
export const isLongBreadcrumbVisible = createSelector(navigationSelector, (state) => state.isLongBreadCrumbVisible);

export const getCurrentNavigationTargets = createSelector(getNavigationStack, (stack) =>
  stack.map((t) => t.navigationTarget),
);
export const getTopNavigationStackItem = createSelector(getNavigationStack, (stack) =>
  stack.length ? stack[stack.length - 1] : null,
);
export const getEntityIdFromUrl = createSelector(getTopNavigationStackItem, (topNavigationTarget) =>
  topNavigationTarget?.navigationTarget ? topNavigationTarget?.navigationTarget['entityId'] : undefined,
);
export const getViewIdFromUrl = createSelector(getTopNavigationStackItem, (topNavigationTarget) =>
  topNavigationTarget?.navigationTarget ? topNavigationTarget?.navigationTarget['viewId'] : undefined,
);
export const getFormIdFromUrl = createSelector(getTopNavigationStackItem, (topNavigationTarget) =>
  topNavigationTarget?.navigationTarget ? topNavigationTarget?.navigationTarget['formId'] : undefined,
);
export const getRecordIdFromUrl = createSelector(getTopNavigationStackItem, (topNavigationTarget) =>
  topNavigationTarget?.navigationTarget ? topNavigationTarget?.navigationTarget['recordId'] : undefined,
);
export const getKanbanIdFromUrl = createSelector(getTopNavigationStackItem, (topNavigationTarget) =>
  topNavigationTarget?.navigationTarget ? topNavigationTarget?.navigationTarget['kanbanId'] : undefined,
);
export const getQueryParams = createSelector(getTopNavigationStackItem, (topNavigationTarget) =>
  topNavigationTarget?.routerState?.queryParams ? topNavigationTarget.routerState.queryParams : {},
);
export const getDataQueryParam = createSelector(getQueryParams, (queryParams) => {
  const args = queryParams['data'];
  return args ? JSON.parse(args) : null;
});
export const getArgsQueryParam = createSelector(getQueryParams, (queryParams) => {
  const args = queryParams['args'];
  return args ? JSON.parse(args) : null;
});
export const getCurrentViewQueryParams = createSelector(getQueryParams, getViewIdFromUrl, (queryParams, viewId) => {
  const viewQuery = viewId ? queryParams[viewId] : undefined;
  if (viewQuery) {
    return JSON.parse(viewQuery, (JSON as any).dateParser) as UrlState;
  }
  return {} as UrlState;
});
export const getSubGridQueryParams = (subgridId: string) =>
  createSelector(getQueryParams, (queryParams) => {
    const subgridQuery = queryParams[subgridId];
    if (subgridQuery) {
      return JSON.parse(subgridQuery, (JSON as any).dateParser) as UrlState;
    }
    return {} as UrlState;
  });

export const getCurrentNavigationPath = createSelector(getTopNavigationStackItem, (item) => item?.navigationPath ?? '');
export const getCurrentNavigationState = createSelector(getTopNavigationStackItem, (item) => item?.state ?? undefined);
export const getCurrentEntityForms = createSelector(getTopNavigationStackItem, getEntityForms, (item, forms) => {
  if (!item?.navigationTarget || !item.navigationTarget.formId) {
    return [];
  }
  return item?.navigationTarget?.entityId ? forms[item?.navigationTarget?.entityId] ?? [] : [];
});
export const getIsDirty = createSelector(getTopNavigationStackItem, (item) => item?.isDirty ?? false);

export const getNavigationTargetsValues = createSelector(getNavigationTargets, (targets) =>
  targets ? Object.values(targets) : [],
);

export const isRecordlessForm = createSelector(getTopNavigationStackItem, (item) => {
  return item?.navigationTarget?.formId != null && item.navigationTarget.entityId == null;
});

export const shouldConfirmNavigation = createSelector(isRecordlessForm, getIsDirty, (isRecordlessForm, isDirty) => {
  return !isRecordlessForm && isDirty;
});
