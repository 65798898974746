import { Injectable, inject } from '@angular/core';
import { EngineCultureService } from './culture.service';
import { DatePipe } from '@angular/common';
import { MomentService } from '@shared/moment/services/moment.service';
import { Moment } from 'moment-timezone';

@Injectable()
export class EngineDateParser {
  private _datePipe = inject(DatePipe);
  private _momentService = inject(MomentService);
  private _cultureService = inject(EngineCultureService);

  constructor() {}

  createDate(
    year: number,
    month: number,
    day: number,
    hour: number,
    minute: number,
    second: number,
    timezone?: string,
  ): Moment {
    return this._momentService.createDate(
      year,
      month,
      day,
      hour,
      minute,
      second,
      timezone ?? this._cultureService.cultureSettings.ianaTimeZone,
    );
  }

  parseFromDate(date: Date, timezone?: string): Moment {
    return this.parseFromTime(date ? date.getTime() : undefined, timezone);
  }

  parseFromTime(date: number, timezone?: string): Moment {
    return this._momentService.parseFromTime(date, timezone ?? this._cultureService.cultureSettings.ianaTimeZone);
  }

  parseFromString(date: string, dateFormat?: string, timezone?: string): Moment {
    return date ? this._momentService.parseFromString(
      date,
      dateFormat ?? this._cultureService.cultureSettings.dateTimeShortPattern,
      true,
      timezone ?? this._cultureService.cultureSettings.ianaTimeZone,
    ) : undefined;
  }

  toString(date: Date, format: string): string {
    if (!date) return '';
    return this._datePipe.transform(date, format);
  }
}
