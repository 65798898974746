import { ChangeDetectorRef, Directive, HostBinding, Input } from '@angular/core';
import { WidthType, AlignStyle } from '@core/services/api-clients';
import { HideableDirective } from './hideable.directive';

@Directive({
  selector: '[positionable]',
})
export class PositionableDirective extends HideableDirective {
  @Input() alignStyle: AlignStyle = AlignStyle.Left;
  @Input() widthType: WidthType = WidthType.AdjustToText;
  @Input() width: number | undefined = undefined;

  @HostBinding('style') get getPositionableStyle() {
    return {
      ...this.getWidthStyle(),
      ...this.getAlignStyle(),
    };
  }

  constructor(changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }

  private getWidthStyle(): any {
    let style = {};
    switch (this.widthType) {
      case WidthType.Pixel:
        if (Number.isInteger(this.width)) style = { width: `${this.width}px` };
        break;
      case WidthType.Percentage:
        if (Number.isInteger(this.width)) style = { width: `${this.width}%` };
        break;
      case WidthType.AdjustToText:
        style = { width: 'fit-content' };
        break;
      case WidthType.AdjustToSpace:
        style = { width: '100%' };
        break;
    }
    return style;
  }

  private getAlignStyle(): any {
    let style = {};
    switch (this.alignStyle) {
      case AlignStyle.Center:
        style = { margin: 'auto' };
        break;
      case AlignStyle.Right:
        style = { 'margin-left': 'auto' };
        break;
    }
    return style;
  }
}
