import { Component, Input, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { Grid } from './form-grid.model';
import { ScreenSize } from '@core/layout/models/screen-size.enum';
import { LayoutSelectors } from '@core/layout/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-form-grid',
  templateUrl: './form-grid.component.html',
  styleUrls: ['./form-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGridComponent {
  @Input() data: Grid;

  @HostBinding('id')
  get getGridId(): string {
    return this.data.id;
  }

  isCompactMode$: Observable<boolean> = this._store
    .select(LayoutSelectors.getScreenSize)
    .pipe(map((screenSize) => screenSize <= ScreenSize.MD));

  constructor(private _store: Store) {}
}
