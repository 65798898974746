import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormTabDto, FormSectionDto, ControlGroupDto } from '@core/services/api-clients';
import { Subject } from 'rxjs';
import {
  MatLegacyTabChangeEvent as MatTabChangeEvent,
  MatLegacyTabGroup as MatTabGroup,
} from '@angular/material/legacy-tabs';
import { IControlContext, ITabContext, ITabsGroupContext, NotificationGroup } from '../../../../../engine-sdk';
import { EngineFormComponent } from '../engine-form.component';
import { Grid, GridDto } from '../form-grid/form-grid.model';

@Component({
  selector: 'app-form-tabs',
  templateUrl: './form-tabs.component.html',
  styleUrls: ['./form-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTabsComponent implements ITabsGroupContext, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _tabs: ITabContext[] = [];
  private _items: FormTabDto[] = [];
  private _notificationGroups: NotificationGroup[] = [];

  @Input() set tabs(val: FormTabDto[]) {
    this._items = [...val];
    this.prepareData();
  }
  @Input() set notificationGroups(val) {
    this._notificationGroups = val;
    this.tabNotificationGroups = this.createTabNotifications(this._items, this._notificationGroups);
  }
  @Input() selectedTabIndex: number = 0;
  @Output() selectedTabChange = new EventEmitter<number>();
  @Output() afterFormViewInit = new EventEmitter();
  @Output() removeNotification: EventEmitter<NotificationGroup> = new EventEmitter<NotificationGroup>();

  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  gridsData: { [id: string]: Grid } = {};
  tabNotificationGroups: {
    [tabId: number]: {
      attributes: string[];
      groups: NotificationGroup[];
      count: number;
    };
  } = {};

  get notificationGroups(): NotificationGroup[] {
    return this._notificationGroups;
  }
  get tabs(): FormTabDto[] {
    return this._items;
  }

  constructor(private _form: EngineFormComponent, private _change: ChangeDetectorRef) {
    this._form.registerTabsGroup(this);
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  onSelectedTabChange(event: MatTabChangeEvent) {
    if (this.selectedTabIndex == event.index) return;
    this.selectedTabIndex = event.index;
    this.selectedTabChange.emit(event.index);
  }

  getTabNotificationsByName(name: string) {
    this.getTabByName(name)
      .getSections()
      .flatMap((s) => s.getControls())
      .filter((c) => c.name);
  }

  registerTab(tab: ITabContext) {
    this._tabs.push(tab);
  }

  unregisterTab(tab: ITabContext) {
    this._tabs = this._tabs.filter((t) => t != tab);
  }

  //#region ITabsGroupContext
  getTabs(): ITabContext[] {
    return this._tabs;
  }

  getTabByName(name: string): ITabContext {
    return this._tabs.find((t) => t.name == name);
  }

  setTabVisibility(name: string, value: boolean) {
    const index = this.tabs.findIndex((t) => t.name == name);
    const tab = this.tabs[index];
    tab.isVisible = value;

    const selectedIndex =
      this.selectedTabIndex === index && !value ? this.getNearestVisibleTabIndex(index) : this.selectedTabIndex;
    if (selectedIndex && this.selectedTabIndex != selectedIndex) {
      this.selectedTabChange.emit(selectedIndex);
      this.tabGroup.realignInkBar();
    }
    this._change.markForCheck();
  }

  getControls(): IControlContext[] {
    return this._tabs.flatMap((t) => t.getControls());
  }
  //#endregion

  private reduceGrid(container: { id: string; children: any[] }): string[] {
    let containers = [];
    if (!container) return;

    if (container.children.length === 0) {
      return [container.id];
    }
    container.children.forEach((e) => {
      containers.push(...this.reduceGrid(e));
    });
    return containers;
  }

  private prepareData() {
    this.tabs.forEach((tab) => {
      this.gridsData[tab.id] = this.getGridData(tab.layoutGrid, tab.formSections, tab.controlGroups);
    });
  }

  private getGridData(grid: GridDto, formSections: FormSectionDto[], controlGroups: ControlGroupDto[]): Grid {
    return {
      ...grid,
      formSection: formSections.find((s) => s.containerId == grid.id),
      controlGroups: controlGroups.filter((g) => g.containerId == grid.id),
      children: (grid.children ?? []).map((c) => this.getGridData(c, formSections, controlGroups)),
    } as Grid;
  }

  private getNearestVisibleTabIndex(itemIndex: number): number {
    return this.tabs
      .map((e, index) => {
        return {
          index: index,
          ratio: Math.abs(itemIndex - index),
          isVisible: e.isVisible,
        };
      })
      .filter((e) => e.isVisible && e.index !== itemIndex)
      .sort((a, b) => a.ratio - b.ratio)[0]?.index;
  }

  private createTabNotifications(tabs: FormTabDto[], notificationGroups: NotificationGroup[]) {
    const tabsData = tabs.map((tab) => {
      const attributes = [
        ...new Set(
          tab.controlGroups
            .map((e) => e.controls)
            .reduce((a, controls) => a.concat(controls))
            .map((e) => e.primaryAttribute),
        ),
      ];

      const groups = notificationGroups
        .filter((e) => attributes.includes(e.name))
        .filter((e) => e.notifications.length !== 0);
      const notifications = groups.map((e) => e.notifications);
      const count = notifications.length !== 0 ? notifications.reduce((a, n) => a.concat(n)).length : 0;
      return { attributes, groups, count };
    });
    return { ...tabsData };
  }
}
