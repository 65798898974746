import { BehaviorSubject, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { IMetadataProvider, IEntity } from '@core/metadata/imetadata-provider.service'
import { tap } from 'rxjs/operators';


@Injectable()
export class EngineMetadataProviderService extends BehaviorSubject<IEntity[]> implements IMetadataProvider {
  entities: IEntity[] = [
    {
      id: '1',
      name: 'Test Entity 1',
      fields: [
        { id: '1', name: 'Field11', type: 'text' },
        { id: '2', name: 'Field12', type: 'text' },
        { id: '3', name: 'Field13', type: 'text' },
        { id: '4', name: 'Field14', type: 'text' },
      ]
    },
  ];

  constructor() {
    super([]);
    this.init();
  }

  public init() {
    this.getMetadata().pipe(
      tap(value => super.next(value))
    ).subscribe();
  }

  public getMetadata(): Observable<IEntity[]> {
    return of(this.entities);
  }
}
