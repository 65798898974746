import { ChangeDetectionStrategy, Component, forwardRef, Inject, OnInit, Optional, SkipSelf } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ContextService } from '@core/services/context.service';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { IFormExecutionContext, NotificationGroup, NotificationScope } from '../../engine-sdk';
import { NoEventWidgetDirective } from '@core/widgets/directives/no-event-widget.directive';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '@core/widgets/models/iscript-runner.service';
import { IFormServiceState, MainFormService } from './main-form.service';
import { ENGINE_FORM_SERVICE } from '@core/engine-forms/models/iengine-form-service.model';
import { FormActions } from '@core/engine-forms/store';
import { NavigationSelectors, NavigationActions } from '@core/navigation/store';
import { NotificationsSelectors } from '@core/notification/store';
import { EngineFormComponent } from '@core/engine-forms/components/engine-form/engine-form.component';
import { IFormArgs } from './main-form.model';
import { IRecordInfo } from '@core/models/irecord-info.model';
import { NavigationService } from '@core/navigation/services/navigation.service';

@Component({
  selector: 'app-main-form',
  templateUrl: './main-form.component.html',
  styleUrls: ['./main-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: ENGINE_FORM_SERVICE, useClass: MainFormService },
    {
      provide: WidgetDirective,
      useExisting: forwardRef(() => MainFormComponent),
    },
  ],
})
export class MainFormComponent extends NoEventWidgetDirective implements OnInit {
  formData$: Observable<IFormServiceState> = this._formService.getFormStateAsync();
  notificationGroups$: Observable<NotificationGroup[]>;

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    private _store: Store,
    private _context: ContextService,
    @Inject(ENGINE_FORM_SERVICE) private _formService: MainFormService,
    private _navigationService: NavigationService,
  ) {
    super(parentWidget, scriptRunnerService);
    this._minWidgetsNumber = 3; // contextMenu + entityForm + asyncWidget
  }

  ngOnInit(): void {
    this._store.dispatch(new FormActions.LoadCurrentFormDto());

    this.getRecordInfoAsync()
      .pipe(
        first(),
        tap((recordInfo) => {
          this._formService.init(recordInfo);
        }),
      )
      .subscribe();

    this.notificationGroups$ = combineLatest([
      this.getRecordInfoAsync(),
      this._store.select(NotificationsSelectors.getNotificationGroups),
    ]).pipe(
      filter(([recordInfo, _]) => !!recordInfo),
      map(([recordInfo, notificationGroups]) =>
        Object.values(notificationGroups).filter(
          (x) => x.scope === NotificationScope.Attribute && recordInfo.entityName === x.entityName,
        ),
      ),
    );

    // TODO-MP: trzeba się tego pozbyć (o ile się da - sdk)
    this._context
      .getMainFormContextAsync()
      .pipe(
        takeUntil(this._destroy$),
        filter((x) => !!x),
        switchMap((x) => x.getExecutionContext()),
        switchMap((formCtx: IFormExecutionContext) => formCtx.saving()),
        tap((s) => s.saveAsync()),
      )
      .subscribe();
  }

  onFormInitiated(form: EngineFormComponent, args: IFormArgs) {
    if (!args) return;
    if (args.$markAsDirty) {
      Object.keys(args)
        .filter((arg) => arg != '$markAsDirty' && arg != '$mappings')
        .forEach((arg) => form.setFieldValue(arg, args[arg]));
    }
  }

  onIsDirtyChanged(isDirty: boolean) {
    this._store.dispatch(new NavigationActions.SetIsDirty({ isDirty }));
  }

  onSelectedTabChange(index: number) {
    this._navigationService.addQueryParams({ tabIndex: index });
  }

  private getRecordInfoAsync(): Observable<IRecordInfo> {
    return this._store.select(NavigationSelectors.getCurrentNavigationTargets).pipe(
      takeUntil(this._destroy$),
      filter((targets) => !!targets.length),
      map((targets) => targets.slice(-1)[0]),
      map((currentNavigationTarget) => {
        return {
          entityId: currentNavigationTarget.entityId,
          entityName: currentNavigationTarget.entityName,
          recordId: currentNavigationTarget.recordId,
        };
      }),
      first(),
    );
  }
}
