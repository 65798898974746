<ng-container *ngIf="formControl">
  <button
    type="button"
    [id]="id"
    [name]="name"
    (click)="onClick()"
    mat-raised-button
    [matTooltip]="tooltipText"
    [disabled]="formControl.disabled">
    <app-icon [icon]="icon"></app-icon>
    <span>{{ label }}</span>
  </button>
</ng-container>
