import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-redirect',
  template: '',
})
export class B2CRedirectComponent implements OnInit {
  constructor(private authService: MsalService, private router: Router) {}

  ngOnInit(): void {
    this.authService.getLogger().verbose('MsalRedirectComponent activated');
    this.authService.handleRedirectObservable().subscribe((x) => {
      if (x == null && this.router.url.startsWith('/auth')) this.router.navigate(['/']);
    });
  }
}
