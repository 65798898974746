import { Pipe, PipeTransform } from '@angular/core';
import { GridColumn, OptionSetValue } from '../models/grid-definition.model';

@Pipe({
  name: 'optionSet',
})
export class OptionSetPipe implements PipeTransform {
  transform(dataItem: any, column: GridColumn): string {
    if (!column.primaryField) {
      return dataItem[column.primaryField];
    }

    if (column.optionSet.isFlags) {
      let stringValues: string[] = dataItem[column.primaryField].split(',').map((e) => e.trim());
      return column.optionSet.values
        .filter((o) => stringValues.indexOf(o.name) >= 0)
        .map((o) => this.getLabel(o))
        .join(', ');
    }

    const option = column.optionSet.values.find((e) => e.name === dataItem[column.primaryField]);
    return this.getLabel(option);
  }

  private getLabel(option: OptionSetValue) {
    return option ? (!option.label.startsWith('@') ? option.label : option.name) : '';
  }
}
