import { Injector } from '@angular/core';
import { CancellationToken } from '@core/services/context.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IMenuItemExecutionContext, IExecutionEvent, ExecutionEventType } from 'src/engine-sdk';
import { ExecutionContext } from './execution-context';

export class MenuItemExecutionContext extends ExecutionContext implements IMenuItemExecutionContext {
  private _events: Observable<IExecutionEvent>;

  constructor(
    injector: Injector,
    events: Observable<IExecutionEvent>,
    cancellationToken: CancellationToken,
    executionEvent?: IExecutionEvent,
    dialogId?: string,
  ) {
    super(injector, cancellationToken, executionEvent, dialogId);
    this._events = events;
  }

  click(): Observable<IExecutionEvent> {
    return this._events.pipe(filter((e) => e.eventType === ExecutionEventType.OnClick));
  }
}
