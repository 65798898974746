import { Directive, Inject, Injector, Input, Optional, SkipSelf } from '@angular/core';
import { LabelControlDto } from '@core/services/api-clients';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';
import { EngineFormControlDirective } from './engine-form-control.directive';
import { LabelControlComponent } from '@shared/reactive-controls/components/label/label-control.component';

@Directive({
  selector: 'app-label-control[engineLabelFormControl]',
  providers: [{ provide: EngineFormControlDirective, useExisting: EngineLabelFormControlDirective }],
})
export class EngineLabelFormControlDirective extends EngineFormControlDirective {
  protected get _labelBaseControl(): LabelControlComponent {
    return this._baseControl as LabelControlComponent;
  }

  @Input() set engineLabelFormControlDefinition(definition: LabelControlDto) {
    this._labelBaseControl.text = definition.value;
    this.engineControlDefinition = definition;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
  ) {
    super(parentWidget, scriptRunnerService, injector);
  }
}
