import { ODataQuery } from '@core/odata';
import { BaseODataService } from '@core/services/base-odata.service';
import { forkJoin, Observable } from 'rxjs';
import { IODataService } from 'src/engine-sdk';

export class ODataService implements IODataService {
  constructor(private _odata: BaseODataService<any>) {}

  add(record: any): Observable<any> {
    return this._odata.create(record);
  }

  addRange(records: any[]): Observable<any> {
    return forkJoin(records.map((record) => this._odata.create(record)));
  }

  get(id: string): Observable<any> {
    return this._odata.get(id);
  }

  update(id: string, record: any): Observable<any> {
    return this._odata.update(id, record);
  }

  updateRange(records: { id: string; record: any }[]): Observable<any> {
    return forkJoin(records.map((record) => this._odata.update(record.id, record.record)));
  }

  delete(id: string): Observable<any> {
    return this._odata.delete(id);
  }

  deleteRange(ids: string[]): Observable<any> {
    return forkJoin(ids.map((id) => this._odata.delete(id)));
  }

  query(): ODataQuery<any> {
    return this._odata.query();
  }
}
