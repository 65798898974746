import { Pipe, PipeTransform } from '@angular/core';
import { GridColumn } from '../models/grid-definition.model';

@Pipe({ name: 'iconUrl' })
export class IconUrlPipe implements PipeTransform {
  transform(dataItem: any, column: GridColumn): string {
    if (!dataItem || column.iconMode != 'url') return null;

    if (column.iconMode == 'url') {
      let fields = column.primaryField.split('.');
      let item = dataItem;
      fields.forEach((element) => {
        if (!item) return null;
        item = item[element];
      });

      return item ? item : column.iconUrl;
    }
  }
}
