import { ChangeDetectorRef, Directive, Inject, Injector, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ChipsControlComponent } from '@shared/reactive-controls/components/chips/chips-control.component';
import { Observable } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import { IInlineRelationControlContext, ISimpleRecord } from '../../../engine-sdk';
import { IAutocompleteOption } from '../../../shared/reactive-controls/components/autocomplete/autocomplete-control.model';
import { EntityDto, InlineRelationControlDto } from '../../services/api-clients';
import { ToolsService } from '../../services/tools-service';
import { WidgetDirective } from '../../widgets/directives/widget.directive';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '../../widgets/models/iscript-runner.service';
import { EngineInlineRelationService } from '../services/engine-inline-relation.service';
import { EngineFormControlDirective } from './engine-form-control.directive';

@Directive({
  selector: 'app-chips-control[engineInlineRelationFormControl]',
  providers: [
    EngineInlineRelationService,
    { provide: EngineFormControlDirective, useExisting: EngineInlineRelationFormControlDirective },
  ],
})
export class EngineInlineRelationFormControlDirective
  extends EngineFormControlDirective
  implements IInlineRelationControlContext, OnInit
{
  private _sourceEntity: EntityDto;
  private _sourceAttributeName: string;
  private _relationId: string;
  private _formId: string;
  private _isCreateButtonHidden: boolean;
  protected get _chipsControl(): ChipsControlComponent {
    return this._baseControl as ChipsControlComponent;
  }

  @Input() set engineInlineRelationControlDefinition(definition: InlineRelationControlDto) {
    this.sourceEntity = definition.sourceEntity;
    this.sourceAttributeName = definition.sourceAttributeName;
    this.relationId = definition.relationId;
    this.formId = definition.formId;
    this.isCreateButtonHidden = !definition.formId || definition.isCreateButtonHidden;
    this.engineControlDefinition = definition;
  }

  @Input() set sourceEntity(v: EntityDto) {
    this._sourceEntity = v;
  }
  @Input() set sourceAttributeName(v: string) {
    this._sourceAttributeName = v;
  }
  @Input() set relationId(v: string) {
    this._relationId = v;
  }

  @Input() set formId(v: string) {
    this._formId = v;
  }

  @Input() set isCreateButtonHidden(v: boolean) {
    this._isCreateButtonHidden = v;
  }

  get sourceEntity(): EntityDto {
    return this._sourceEntity;
  }
  get sourceAttributeName(): string {
    return this._sourceAttributeName;
  }
  get relationId(): string {
    return this._relationId;
  }
  get formId(): string {
    return this._formId;
  }
  get isCreateButtonHidden(): boolean {
    return this._isCreateButtonHidden;
  }
  set options(v: ISimpleRecord[]) {
    this._chipsControl.options = (v || []).map((x) => {
      return { value: x.id, label: x.name };
    });
    this._changes.markForCheck();
  }

  get options(): ISimpleRecord[] {
    return this._chipsControl.options.map((x) => {
      return { id: x.value, name: x.label };
    });
  }

  filterChange: Observable<string>;
  optionSelect: Observable<ISimpleRecord>;

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
    private _inlineRelationService: EngineInlineRelationService,
    private _changes: ChangeDetectorRef,
    private _toolsService: ToolsService,
  ) {
    super(parentWidget, scriptRunnerService, injector);

    this.filterChange = this._chipsControl.filterChange
      .asObservable()
      .pipe(takeUntil(this._destroy$), debounceTime(500));

    this.optionSelect = this._chipsControl.optionSelect.asObservable().pipe(
      takeUntil(this._destroy$),
      tap((_) => (this.options = [])),
      map((x) => {
        return <ISimpleRecord>{ id: x.value, name: x.label };
      }),
    );

    this._form.isWidgetLoaded$
      .pipe(
        takeUntil(this._destroy$),
        tap(() =>
          this._inlineRelationService.init(
            this.sourceEntity,
            this.sourceAttributeName,
            this._form.getEntityInfo().recordId,
          ),
        ),
      )
      .subscribe();
  }

  ngOnInit() {
    this._inlineRelationService.relatedRecords$
      .pipe(
        takeUntil(this._destroy$),
        tap((options) => {
          this._chipsControl.chips = options;
          this._changes.markForCheck();
        }),
      )
      .subscribe();

    this._chipsControl.doubleClick
      .asObservable()
      .pipe(
        takeUntil(this._destroy$),
        tap((chip) => this.onDoubleClick(chip)),
      )
      .subscribe();

    this._chipsControl.removeClick
      .asObservable()
      .pipe(
        takeUntil(this._destroy$),
        tap((chip) => this.onRemoveClick(chip)),
      )
      .subscribe();

    this._chipsControl.addClick
      .asObservable()
      .pipe(
        takeUntil(this._destroy$),
        tap((_) => this.onAddClick()),
      )
      .subscribe();
  }

  add(record: ISimpleRecord) {
    this._chipsControl.add({ value: record.id, label: record.name });
    this._changes.markForCheck();
  }

  remove(recordId: string) {
    this._chipsControl.remove(recordId);
    this._changes.markForCheck();
  }

  private onDoubleClick(chip: IAutocompleteOption) {
    if (this.formId) {
      this._toolsService.openFormDialog({
        entityId: this.sourceEntity.id,
        entityName: this.sourceEntity.name,
        recordId: chip.value,
        formId: this.formId,
        onSuccess: (_, record) => {
          chip.label = record.Name;
          this._changes.markForCheck();
        },
      });
    }
  }

  private onRemoveClick(chip: IAutocompleteOption) {
    this._inlineRelationService
      .remove(chip.value)
      .pipe(
        takeUntil(this._destroy$),
        tap((_) => {
          this._chipsControl.remove(chip.value);
          this._changes.markForCheck();
        }),
      )
      .subscribe();
  }

  private onAddClick() {
    if (this.formId) {
      this._toolsService.openFormDialog({
        entityId: this.sourceEntity.id,
        entityName: this.sourceEntity.name,
        formId: this.formId,
        args: { [`${this.sourceAttributeName}Id`]: this._form.getEntityInfo().recordId },
        onSuccess: (_, record) => {
          this._chipsControl.add({ value: record.Id, label: record.Name });
          this._changes.markForCheck();
        },
      });
    }
  }
}
