import { ChangeDetectionStrategy, Component, forwardRef, Inject, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlDirective } from '@shared/reactive-controls/directives/base-form-control.directive';
import { CULTURE_SERVICE, ICultureService } from '@shared/reactive-controls/models/iculture-service.model';

@Component({
  selector: 'app-yes-no-control',
  templateUrl: './yes-no-control.component.html',
  styleUrls: ['./yes-no-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YesNoControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: YesNoControlComponent,
    },
  ],
})
export class YesNoControlComponent extends BaseFormControlDirective {
  constructor(@Inject(CULTURE_SERVICE) cultureService: ICultureService, injector: Injector) {
    super(cultureService, injector);
  }
}
