import { Injectable } from '@angular/core';
import { EngineFormComponent } from '@core/engine-forms/components/engine-form/engine-form.component';
import { IEngineFormService } from '@core/engine-forms/models/iengine-form-service.model';
import { FormActions, FormSelectors } from '@core/engine-forms/store';
import { IPerformOpertionAndNavigate } from '@core/engine-forms/store/actions';
import { IRecordInfo } from '@core/models/irecord-info.model';
import { NavigationActions } from '@core/navigation/store';
import { EntityMappingClient, GetPrefilledRecordQuery } from '@core/services/api-clients';
import { GenericODataServiceProvider } from '@core/services/generic-odata-service-provider';
import { Store } from '@ngrx/store';
import { AsyncSubject, BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, first, map, tap, withLatestFrom, mergeMap, filter } from 'rxjs/operators';
import { IFormArgs, KnownFormQueryParams } from './main-form.model';
import { FormDefinition } from '@core/engine-forms/components/engine-form/engine-form.model';
import { LodashService } from '@core/services/lodash.service';
import { FormContextType } from 'src/engine-sdk';
import { NavigationService } from '@core/navigation/services/navigation.service';

export interface IFormServiceState {
  recordInfo?: IRecordInfo;
  definition?: FormDefinition;
  selectedTabIndex?: number;
  args?: IFormArgs;
  dataItem?: { [key: string]: any };
}

@Injectable()
export class MainFormService implements IEngineFormService {
  private _formState = new BehaviorSubject<IFormServiceState>({});

  constructor(
    private _store: Store,
    private _odataServiceProvider: GenericODataServiceProvider,
    private _entityMappingClient: EntityMappingClient,
    private _navigationService: NavigationService,
  ) {}

  getFormStateAsync(): Observable<IFormServiceState> {
    return this._formState.asObservable();
  }

  init(recordInfo: IRecordInfo) {
    this._formState.next({ recordInfo });

    this._store
      .select(FormSelectors.getFormFromUrl)
      .pipe(
        filter((currentForm) => !!currentForm),
        map((currentForm) => {
          const form = LodashService.cloneDeep(currentForm) as FormDefinition;
          form.recordInfo = this._formState.value.recordInfo;
          form.contextInfo = { type: FormContextType.Main };
          return form;
        }),
        first(),
        tap((definition) => this._formState.next({ ...this._formState.value, definition })),
      )
      .subscribe();

    this.getFormArgsAsync()
      .pipe(tap((args) => this._formState.next({ ...this._formState.value, args })))
      .subscribe();

    this.getDataItem(this._formState.value.recordInfo.entityName, this._formState.value.recordInfo.recordId)
      .pipe(
        withLatestFrom(this.getFormArgsAsync()),
        map(([record, args]) => {
          const entityArgs = this.getPrefillFormArgs(args);
          if (entityArgs) {
            record = Object.assign(record, entityArgs);
          }
          return record;
        }),
        mergeMap((record) => this.getPrefilledRecord(record)),
        first(),
        tap((record) => this._formState.next({ ...this._formState.value, dataItem: record })),
      )
      .subscribe();

    this._navigationService
      .getQueryParam(KnownFormQueryParams.TabIndex)
      .pipe(
        map((index) => (index ? +index : 0)),
        first(),
        tap((index) => this._formState.next({ ...this._formState.value, selectedTabIndex: index })),
      )
      .subscribe();
  }

  getPrefilledRecord(record: any): Observable<any> {
    if (record.Id || !this._formState.value.recordInfo.entityName) return of(record);

    const getPrefilledRecordQuery = {
      entityName: this._formState.value.recordInfo.entityName,
      recordValues: record,
    } as GetPrefilledRecordQuery;

    return this._entityMappingClient.getPrefilledRecord(getPrefilledRecordQuery).pipe(
      map((prefilled) => {
        record = Object.assign(record, prefilled);
        return record;
      }),
    );
  }

  save(engineForm: EngineFormComponent): Observable<any> {
    const isCreateMode = !engineForm.definition.recordInfo.recordId;
    const result$ = new AsyncSubject<any>();
    if (isCreateMode) {
      this._store.dispatch(
        this.getActionWithNavigation(
          engineForm,
          engineForm.id,
          engineForm.definition.recordInfo,
          engineForm.dataItem,
          FormActions.CreateEntityAndNavigateToUpadateForm,
          result$,
        ),
      );
    } else {
      this._store.dispatch(
        new FormActions.UpdateEntity({
          entityName: engineForm.definition.recordInfo.entityName,
          entityId: engineForm.definition.recordInfo.recordId,
          entity: engineForm.getPatchDataItem(),
          onSuccess: () => {
            engineForm
              .refetchData()
              .pipe(
                catchError((error) => {
                  result$.error(error);
                  return of(error);
                }),
                tap((_) => {
                  this._store.dispatch(new NavigationActions.SetIsDirty({ isDirty: false }));
                  engineForm.markAsPristine();
                  result$.next(true);
                  result$.complete();
                }),
              )
              .subscribe();
          },
          onFailure: (error) => {
            result$.error(error);
          },
        }),
      );
    }

    return result$;
  }

  saveAndNew(engineForm: EngineFormComponent): Observable<any> {
    const isCreateMode = !engineForm.definition.recordInfo.recordId;
    const result$ = new AsyncSubject<any>();
    if (isCreateMode) {
      this._store.dispatch(
        this.getActionWithNavigation(
          engineForm,
          engineForm.id,
          engineForm.definition.recordInfo,
          engineForm.dataItem,
          FormActions.CreateEntityAndNavigateToCreateForm,
          result$,
        ),
      );
    } else {
      this._store.dispatch(
        this.getActionWithNavigation(
          engineForm,
          engineForm.id,
          engineForm.definition.recordInfo,
          engineForm.getPatchDataItem(),
          FormActions.UpdateEntityAndNavigateToCreateForm,
          result$,
        ),
      );
    }

    return result$;
  }

  saveAndClose(engineForm: EngineFormComponent): Observable<any> {
    const isCreateMode = !engineForm.definition.recordInfo.recordId;
    const result$ = new AsyncSubject<any>();
    if (isCreateMode) {
      this._store.dispatch(
        this.getActionWithNavigation(
          engineForm,
          engineForm.id,
          engineForm.definition.recordInfo,
          engineForm.dataItem,
          FormActions.CreateEntityAndNavigateBack,
          result$,
        ),
      );
    } else {
      this._store.dispatch(
        this.getActionWithNavigation(
          engineForm,
          engineForm.id,
          engineForm.definition.recordInfo,
          engineForm.getPatchDataItem(),
          FormActions.UpdateEntityAndNavigateBack,
          result$,
        ),
      );
    }

    return result$;
  }

  refetch(engineForm: EngineFormComponent): Observable<any> {
    const result$ = new AsyncSubject<any>();

    this.getDataItem(engineForm.definition.recordInfo.entityName, engineForm.definition.recordInfo.recordId)
      .pipe(
        catchError((error) => {
          result$.error(error);
          return of(error);
        }),
        tap((dataItem) => this._formState.next({ ...this._formState.value, dataItem })),
        delay(100), // delay to avoid hazards
        tap((entityValue) => {
          result$.next(entityValue);
          result$.complete();
        }),
      )
      .subscribe();

    return result$;
  }

  private getFormArgsAsync(): Observable<any> {
    return this._navigationService
      .getQueryParam(KnownFormQueryParams.Args)
      .pipe(map((args) => (args ? JSON.parse(args) : null)));
  }

  private getDataItem(entityName: string, recordId: string): Observable<any> {
    if (!recordId) return of({ Id: undefined });
    const service = this._odataServiceProvider.create(entityName);
    return service.get(recordId).pipe(first());
  }

  private getPrefillFormArgs(args: IFormArgs): any {
    if (args?.$markAsDirty) return {};
    let entityArgs = { ...args };
    delete entityArgs.$mappings;
    delete entityArgs.$markAsDirty;
    return entityArgs;
  }

  private getActionWithNavigation<TAction>(
    engineForm: EngineFormComponent,
    formId: string,
    recordInfo: IRecordInfo,
    entityValue: any,
    type: new (payload) => TAction,
    results$: AsyncSubject<any>,
  ): TAction {
    let payload: IPerformOpertionAndNavigate = {
      entityName: recordInfo.entityName,
      entity: entityValue,
      formId: formId,
      entityId: recordInfo.entityId,
      recordId: recordInfo.recordId,
      onOperationSuccess: () => {
        this._store.dispatch(new NavigationActions.SetIsDirty({ isDirty: false }));
        engineForm.markAsPristine();
      },
      onOperationFailure: (error) => {
        results$.error(error);
      },
      onNavigationSuccess: () => {
        results$.next(true);
        results$.complete();
      },
    };
    return new type(payload);
  }
}
