import { Pipe, PipeTransform } from '@angular/core';
import { GridColumn } from '../models/grid-definition.model';

@Pipe({
  name: 'combinedTexts',
})
export class CombinedTextsPipe implements PipeTransform {
  transform(dataItem: any, column: GridColumn): string {
    if (!dataItem) return null;
    const combinedTexts = [
      this.getFieldValue(dataItem, column.primaryField),
      this.getFieldValue(dataItem, column.secondaryField),
    ].filter((x) => !!x);
    return combinedTexts.join(column.combinedTextsSeparator ?? ', ');
  }

  private getFieldValue(dataItem: any, fieldName: string) {
    if (!dataItem || !fieldName) return null;
    let fields = fieldName.split('.');
    let item = dataItem;

    fields.forEach((element) => {
      if (!item) return null;
      item = item[element];
    });
    return item;
  }
}
