import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ODataConfiguration } from '@core/odata';

@Injectable()
export class ODataConfig extends ODataConfiguration {
  constructor() {
    super();

    this.baseUrl = environment.urls.ODataUrl;
    this.postRequestOptions = {
      observe: 'response',
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    };
  }
}
