<ng-container *ngIf="data.children.length === 0">
  <engine-control-group
    *ngFor="let item of data.controlGroups"
    [id]="item.id"
    [controlGroup]="item"
    [style.flex]="getFlexSpan(1)"></engine-control-group>
</ng-container>
<ng-container *ngIf="data.children.length !== 0">
  <app-form-grid
    *ngFor="let childGrid of data.children"
    [data]="childGrid"
    [style.flex]="isCompactMode ? '1 100%' : getFlexSpan(childGrid.takenSlots)">
  </app-form-grid>
</ng-container>
<div *ngIf="getUnusedSlotsCount() > 0" [style.flex]="getFlexRestSpan()"></div>
