import { Directive, Inject, Injector, Input, Optional, SkipSelf } from '@angular/core';
import { TextAreaControlDto } from '@core/services/api-clients';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';
import { TextAreaControlComponent } from '@shared/reactive-controls/components/text-area/text-area-control.component';
import { EngineFormControlDirective } from './engine-form-control.directive';

@Directive({
  selector: 'app-text-area-control[engineTextAreaFormControl]',
  providers: [{ provide: EngineFormControlDirective, useExisting: EngineTextAreaFormControlDirective }],
})
export class EngineTextAreaFormControlDirective extends EngineFormControlDirective {
  protected get _textAreaBaseControl(): TextAreaControlComponent {
    return this._baseControl as TextAreaControlComponent;
  }

  @Input() set engineTextAreaControlDefinition(definition: TextAreaControlDto) {
    this.minRowsNumber = definition.minRowsNumber;
    this.maxRowsNumber = definition.maxRowsNumber;
    this.engineControlDefinition = definition;
  }

  @Input() set minRowsNumber(minRowsNumber: number) {
    this._textAreaBaseControl.minRowsNumber = minRowsNumber;
  }

  @Input() set maxRowsNumber(maxRowsNumber: number) {
    this._textAreaBaseControl.maxRowsNumber = maxRowsNumber;
  }

  get minRowsNumber(): number {
    return this._textAreaBaseControl.minRowsNumber;
  }

  get maxRowsNumber(): number {
    return this._textAreaBaseControl.maxRowsNumber;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
  ) {
    super(parentWidget, scriptRunnerService, injector);
  }
}
