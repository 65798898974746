import { Component, forwardRef, Inject, Input, Optional, SkipSelf } from '@angular/core';
import { Observable } from 'rxjs';
import { IconDto } from '@core/services/api-clients';
import { ContextService } from '@core/services/context.service';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { IExecutionContext } from 'src/engine-sdk';
import { WidgetType } from '@core/widgets/models/widget-type';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  providers: [
    {
      provide: WidgetDirective,
      useExisting: forwardRef(() => MenuItemComponent),
    },
  ],
})
export class MenuItemComponent extends WidgetDirective {
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() shortLabel: string = '';
  @Input() icon: IconDto = null;
  @Input() isWide: boolean = false;
  @Input() isRootLevel: boolean = true;

  public constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    private _contextService: ContextService,
  ) {
    super(parentWidget, scriptRunnerService);
    this._isOnClickEventSupported = true;
  }

  getWidgetType(): WidgetType {
    return WidgetType.MenuItem;
  }

  getExecutionContext(): Observable<IExecutionContext> {
    return this._contextService.createMenuItemExecutionContext(this._events$);
  }
}
