import { Directive, Inject, Optional, SkipSelf } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { WidgetDirective } from './widget.directive';
import { IExecutionContext } from 'src/engine-sdk';
import { WidgetType } from '../models/widget-type';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '../models/iscript-runner.service';

@Directive({
  selector: '[engineNoEventWidget]',
})
export class NoEventWidgetDirective extends WidgetDirective {
  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
  ) {
    super(parentWidget, scriptRunnerService);
  }

  getWidgetType(): WidgetType {
    return WidgetType.NoEventsWidget;
  }

  getExecutionContext(): Observable<IExecutionContext> {
    return EMPTY;
  }
}
