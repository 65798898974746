import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom, mergeMap } from 'rxjs/operators';
import { LocalizationClient, PermissionClient, UILanguageDto, UserClient } from '@core/services/api-clients';
import { Store } from '@ngrx/store';
import {
  CheckCurrentUserPermissionKey,
  LoadUILanguages,
  LoadUILanguagesSuccess,
  LoadUserData,
  LoadUserDataFailure,
  LoadUserDataSuccess,
  SetUserLanguage,
  SetUserLanguageSuccess,
  UserActionTypes,
} from './actions';
import { UserActions, UserSelectors } from '.';
import { HandleError } from '@core/errors/store/actions';
import { GenericODataServiceProvider } from '@core/services/generic-odata-service-provider';
import { EngineCultureService } from '@core/engine-culture/services/culture.service';

@Injectable()
export class UserEffects {
  loadUserData$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadUserData>(UserActionTypes.LoadUserData),
      switchMap(() => this._userService.getUserProfile()),
      map((user) => new LoadUserDataSuccess({ user: user })),
      catchError((error) => {
        if (error?.payload?.code === 403) {
          return of(new LoadUserDataFailure());
        } else {
          this._store.dispatch(new HandleError({ error }));
          return EMPTY;
        }
      }),
    ),
  );

  loadUserDataSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<LoadUserDataSuccess>(UserActionTypes.LoadUserDataSuccess),
        map((action) => action.payload.user),
        tap((userData) => {
          this._cultureService.changeSettings({
            ...this._cultureService.cultureSettings,
            ...userData.culture,
            languageId: userData.language.id,
            code: userData.language.code,
            rtl: false,
            ianaTimeZone: userData.ianaTimeZone,
          });
        }),
      ),
    { dispatch: false },
  );

  loadLanguages$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadUILanguages>(UserActionTypes.LoadUILanguages),
      switchMap((_) => {
        return this._localizationClient.uILanguages();
      }),
      map((languages) => new LoadUILanguagesSuccess({ uiLanguages: languages })),
      catchError((error) => {
        this._store.dispatch(new HandleError({ error }));
        return EMPTY;
      }),
    ),
  );

  setUserLanguage$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SetUserLanguage>(UserActionTypes.SetUserLanguage),
      map((data) => data.payload.language),
      withLatestFrom(this._store.select(UserSelectors.getCurrentUserId)),
      switchMap(([language, userId]) =>
        this._odataServiceProvider
          .create('User')
          .update(userId, { LanguageId: language.id })
          .pipe(map(() => language)),
      ),
      tap((language) =>
        this._cultureService.changeSettings({
          ...this._cultureService.cultureSettings,
          languageId: language.id,
          code: language.code,
        }),
      ),
      switchMap((language) => of(new SetUserLanguageSuccess({ language: language as UILanguageDto }))),
    ),
  );

  checkCurrentUserPermissionKey$ = createEffect(() =>
    this._actions$.pipe(
      ofType<CheckCurrentUserPermissionKey>(UserActionTypes.CheckCurrentUserPermissionKey),
      map((action) => action.payload.permissionKeyId),
      mergeMap((permissionKeyId) =>
        of(permissionKeyId).pipe(
          withLatestFrom(this._store.select(UserSelectors.checkCurrentUserPermissionKey(permissionKeyId))),
        ),
      ),
      mergeMap(([permissionKeyId, hasKey]) => {
        if (!hasKey) {
          return this._permissionClient.checkCurrentUserPermissionKey(permissionKeyId).pipe(
            mergeMap((result) =>
              of(
                new UserActions.CheckCurrentUserPermissionKeySuccess({
                  permissionKeyId: permissionKeyId,
                  result: result,
                }),
              ),
            ),
          );
        }

        return EMPTY;
      }),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _userService: UserClient,
    private _cultureService: EngineCultureService,
    private _localizationClient: LocalizationClient,
    private _permissionClient: PermissionClient,
    private _odataServiceProvider: GenericODataServiceProvider,
    private _store: Store,
  ) {}
}
