import { Injectable } from '@angular/core';
import { BaseODataService } from './base-odata.service';
import { ODataServiceFactory } from '@core/odata';

@Injectable()
export class GenericODataServiceProvider {
  public constructor(private _serviceFactory: ODataServiceFactory) {}

  create(tableName: string): BaseODataService<{}> {
    return new BaseODataService(this._serviceFactory, tableName);
  }
}
